export const environment = {
  production: true,
  office: 'https://manager.omnihunter.net',
  officeApi: 'https://manager.omnihunter.net/api/v6',
  providerUrl: 'https://vehicleproviderstaging.omnihunter.net/',
  nodeApi: 'https://chat.omnihunter.net/api/v2',
  socketUrl: 'https://chat.omnihunter.net:443',
  agent: 'https://agente.omnihunter.net',
  versionChat:'Versão 1.15.0',
  versionWhatsapp: 'Versão 4.0.9',
};

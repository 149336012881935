import { Injectable, EventEmitter, OnInit } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService implements OnInit {
  socket: any
  user: any = null
  static updateChat = new EventEmitter()
  static visualizeMessage = new EventEmitter()
  static updateSenderScreen = new EventEmitter()
  static socketContactStateChanged = new EventEmitter()

  constructor() {
    this.user = JSON.parse(localStorage.getItem('user'))
  }

  ngOnInit(): void {
    if (this.user)
      this.socket = io(environment.socketUrl);
    else
      console.log('Erro ao obter user');
  }

  connect() {
    this.socket = io.connect(environment.socketUrl, {
      transports: ['websocket'],
      upgrade: true,
      reconnection: true,
      secure: true,
      reconnectionDelay: 10000,
      reconnectionAttempts: 3600,
      'forceNew': true
    });
    
    this.socket.emit("join", this.user._id)

    this.socket.on('connect', () => {
      console.log(`Chat Conectado com o usuário ${this.user.name}`);
    });

    this.socket.on('reconnect', function () {
      this.user = JSON.parse(localStorage.getItem('user'))
      console.log(`Reconectando usuário ${this.user.name} ao chat`);
      this.emit("join", this.user._id);
      // this.emit('GET MESSSAGES NOT RECEIVEDS') !!!
    });
    
    this.socket.on('disconnect', function () {
      console.log('Usuário foi desconectado do chat');
    });

    this.socket.on('UPDATE_SCREENS_SENDER_MESSAGE', function(message){
      console.log('socket: UPDATE_SCREENS_SENDER_MESSAGE');
      
      SocketioService.updateSenderScreen.emit({message: message});
    })

    this.socket.on('NEW_MESSAGE', function(message){
      console.log('socket: NEW_MESSAGE_RECEIVED');
      
      message.to = message.to._id
      SocketioService.updateChat.emit({ message: message })
    })

    this.socket.on('VISUALIZED_MESSAGE', function(chatId){
      console.log('socket: VISUALIZED_MESSAGE');
      SocketioService.visualizeMessage.emit({ chatId: chatId })
    })

    this.socket.on('c', function(message){
      console.log('socket: SHARED_MESSAGE');
      SocketioService.updateChat.emit({ message: message })
    })

    this.socket.on('contact_connected', function(){
      console.log('socket: contact_connected');
      SocketioService.socketContactStateChanged.emit()
    })

    this.socket.on('contact_disconnected', function(){
      console.log('socket: contact_disconnected');
      SocketioService.socketContactStateChanged.emit()
    })
  }

}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationsService } from '../services/notifications.service';
import { AuthenticationService } from './../services/authentication.service';
import { CpfService } from './../services/cpf.service';
import { ApiService } from './../services/api.service';
import { ResourcesService } from './../services/resources.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.styl', './not-allowed.component.scss']
})
export class NotAllowedComponent implements OnInit {

  @Output() authorization = new EventEmitter(); 
  cpf:String;
  password: String;
  showPassword: Boolean = false;
  loading: Boolean = false;
  token: String;
  
  
  user: any;
  accessToken: any;
  product: any;

  constructor( private notifications: NotificationsService, private router: Router, private cpfService: CpfService, 
    private api: ApiService, private authenticate: AuthenticationService, private resourceService: ResourcesService, private location: Location ) { }

  ngOnInit() {

    localStorage.clear();
    this.cpf="";
    this.password="";
    
  }

  largerThanMediumScreen() {
    return window.innerWidth > 800
  }

  tryToLogin() {

    if (this.cpf == undefined || !this.cpfService.isValidCpf(this.cpf))
      this.notifications.showNotification('top', 'right', 4, 'Digite um CPF válido', 4000);
    else if (this.password == undefined || this.password.trim().length < 6)
      this.notifications.showNotification('top', 'right', 4, 'Digite uma senha com pelo menos 6 caracteres', 4000);
    else
      this.makeLogin();
  }

  async makeLogin() {
    
    this.loading = true;
    
    let params = {
      cpf: this.cpfService.unmask(this.cpf),
      password: btoa(this.password.toString()),
      plataform: "chat",
    }

    try {
            
      await this.api.loginWithCpfPassword(params).subscribe(data => {
              //retorno de api/v6/authenticate    
              this.token = data.token;
              let chatUrl = data.chatUrl;
              
              //passando o token recebido anteriormente
              this.authenticate.authenticate(this.token).subscribe(data => {
                  
                    localStorage.setItem('product', JSON.stringify(data.product))
                    localStorage.setItem('user', JSON.stringify(data.user))
                    localStorage.setItem('token', data.accessToken)
                    this.location.replaceState('');
          
                    this.authorization.emit(true);

                    this.loading = false;
                  
                  //With authorization it can access the chat
                  document.location.reload(true);
                  //this.router.navigate([`${chatUrl}?accessid=${this.accessToken}`])
                  
              }, (error) => {
                
                console.log(error)
                
                this.notifications.showNotification('top', 'right', 4, 'Erro de autenticação! Tente novamente!', 4000);

                this.loading = false;
              
              }) 
                      
                this.loading = false;
            
            }, (err: HttpErrorResponse) => {
                
                if(err.error.message == "invalid credentials") {
                    this.notifications.showNotification('top', 'right', 4, 'CPF e/ou Senha inválida(s)! Tente Novamente!', 4000);
                } else if (err.error.message == "access restriction") {
                    this.notifications.showNotification('top', 'right', 4 , 'Usuário bloqueado. Entre em contato com cliente@omnihunter.net', 4000);
                } else if (err.error.message == "invalid user or plataform") {
                    this.notifications.showNotification('top', 'right', 4 , 'Usuário ou senha incorreta. Entre em contato com cliente@omnihunter.net.', 4000);
                }

                this.loading = false;
                   
            });
      
      this.loading = false;

    } catch (error) {

      console.log('11111')

      this.notifications.showNotification('top', 'right', 4, 'Erro de autenticação! Tente novamente!', 4000);

      this.loading = false;
        
    }

    this.loading = false;

  }

  toShowPassword(){
    
    let passwordInput = document.getElementById("password_input")
    
    if(this.showPassword){
      this.showPassword = false
      passwordInput.setAttribute('type', 'password')
    }
    else{
      this.showPassword = true
      passwordInput.setAttribute('type', 'text')
    }

  }

}

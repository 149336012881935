import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-select-contact',
  templateUrl: './select-contact.component.html',
  styleUrls: ['./select-contact.component.styl']
})
export class SelectContactComponent implements OnInit, OnChanges {
  @Input() contacts: any
  toShare: any
  searchingText: string = ''
  @Output() toSelectContacts = new EventEmitter()
  faSearch = faSearch

  constructor(private utils: UtilsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    for(let attr in changes) {
      if (attr == 'contacts' && changes.contacts.currentValue) {
        this.toShare = changes.contacts.currentValue.map(contact => Object.assign({}, contact));
        this.toShare.forEach(selected => selected.selected = false);
        this.contacts = this.toShare
      }
    }
  }

  ngOnInit() {
  }

  toSelectContact(contact) {
    if (contact.selected)
      contact.selected = false
    else
      contact.selected = true
    
    let contactsToShare = this.toShare.filter(contact => contact.selected == true)
    this.toSelectContacts.emit(contactsToShare)
  }

  getUrlProfile(contact){
    let urls = this.utils.getProfileUrl()
    return contact.role == 'locator' ? urls.locatorImageUrl : urls.operatorImageUrl
  }

  filterContact($event) {
    if (this.searchingText != '') {
      this.toShare = this.contacts.filter(chat => this.filterByName(chat, this.searchingText))
    } else {
      this.toShare = this.contacts
    }
  }

  filterByName(chat, text) {
    let name = this.normalizeText(chat.name)
    
    if (name.includes(this.normalizeText(text))) {
      return true;
    }
  }

  normalizeText = (text) => {
    var r = text.toLowerCase();
    r = r.replace(new RegExp("\\s", 'g'), "");
    r = r.replace(new RegExp("[àáâãäå]", 'g'), "a");
    r = r.replace(new RegExp("æ", 'g'), "ae");
    r = r.replace(new RegExp("ç", 'g'), "c");
    r = r.replace(new RegExp("[èéêë]", 'g'), "e");
    r = r.replace(new RegExp("[ìíîï]", 'g'), "i");
    r = r.replace(new RegExp("ñ", 'g'), "n");
    r = r.replace(new RegExp("[òóôõö]", 'g'), "o");
    r = r.replace(new RegExp("œ", 'g'), "oe");
    r = r.replace(new RegExp("[ùúûü]", 'g'), "u");
    r = r.replace(new RegExp("[ýÿ]", 'g'), "y");
    r = r.replace(new RegExp("\\W", 'g'), "");
    return r;
  }

}

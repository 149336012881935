import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.styl']
})
export class AlertDialogComponent implements OnInit {
  onPositive = new EventEmitter()
  positiveBtnTxt: string
  negativeBtnTxt: string
  title : string
  message : string
  anotherMessage: string

  constructor( @Inject(MAT_DIALOG_DATA) public data: any ) {
    this.positiveBtnTxt = data.positiveBtn;
    this.negativeBtnTxt = data.negativeBtn;
    this.title = data.title;
    this.message = data.message;

    if (data.anotherMessage) {
      this.anotherMessage = data.anotherMessage;
    }
     
  }

  ngOnInit() { }

  positiveAction() {
    this.onPositive.emit()
  }

}


import { Component, OnInit, Output } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl', 'app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'omnihunter-chat';
  authorized: Boolean = null;
  externalEvent: any = { backBtn: false }
  @Output() backEvent: String
  

  constructor( private authService: AuthenticationService, private location: Location ){ }

  ngOnInit(): void {
    //O chat pode ser inicializado por dentro do office, do agent ou por login
    //Inicialização do chat por httpParams  

    try {
      let url = window.location.href;
      
      let httpParams = new HttpParams({ fromString: url.split('?')[1] });

      console.log(httpParams);

      if (httpParams.get('accessid') || httpParams.get('accessId')) {
        let accessId    = httpParams.get('accessid') ? httpParams.get('accessid') : httpParams.get('accessId')
        let replyTo     = httpParams.get('chat')
        let channel     = httpParams.get('channel')
        let chatCpf     = httpParams.get('chatCpf')
        let resourceId  = httpParams.get('resource_id')
        let singleUser  = httpParams.get('singleUser')
        let textSubject = httpParams.get('textSubject') || ''
        let textSearch  = httpParams.get('textSearch');
        let referrer    = document.referrer;

        if(referrer && referrer.endsWith('/')) {
            referrer = referrer.substring(0, referrer.length - 1);
        }

        if(referrer.includes("chat") || referrer.includes("4201")) {
            referrer = null;
            console.log(`Origem é CHAT! Não seta referrer`);
        }
        
        console.log(`chatCPF:    ${chatCpf}`);
        console.log(`resourceId: ${resourceId}`);
        console.log(`textSubject:   ${textSubject}`);
        console.log(`singleUser: ${singleUser}`);
        console.log(`referrer:   ${referrer}`);
        
        // inicio
        this.authService.authenticate(accessId).subscribe((response: any) => {
          if (response) {
            
            localStorage.setItem('product',     JSON.stringify(response.product))
            localStorage.setItem('user',        JSON.stringify(response.user))

            localStorage.setItem('token',       response.accessToken)
            localStorage.setItem('chatCpf',     chatCpf)
            localStorage.setItem('resource_id', resourceId)
            localStorage.setItem('textSearch',  textSearch)
            
            if (chatCpf)
              localStorage.setItem('@OMNIHUNTERsingleUser', 'true');
            
            if (textSubject) {
              localStorage.setItem('@OMNIHUNTERtextSubject', `[${textSubject}] `);
              localStorage.setItem('@OMNIHUNTERtextSubjectTitle', textSubject);
            }

            console.log(`response.user.role: ${response.user.role}`);

            if (response.user.role === 'locator') {
                localStorage.setItem('referrer', referrer || environment.agent);
            } else if (response.user.role === 'operator') {
                localStorage.setItem('referrer', referrer || environment.office);
            } else {
                localStorage.setItem('referrer', null);
            }
            
            this.location.replaceState('');
            
            if (replyTo) 
              localStorage.setItem('replyTo', replyTo)
            
            if (channel) 
              localStorage.setItem('channel', channel)
            
            document.location.reload();
            
          } else {
            this.authorized = false
          }
        })
        
      } else if (localStorage.getItem('token')) {
        console.log(`product:                     ${localStorage.getItem('product')}`)
        console.log(`chatCpf:                     ${localStorage.getItem('chatCpf')}`)
        console.log(`resource_id:                 ${localStorage.getItem('resource_id')}`)
        console.log(`@OMNIHUNTERsingleUser:       ${localStorage.getItem('@OMNIHUNTERsingleUser')}`)
        console.log(`@OMNIHUNTERtextSubject:      ${localStorage.getItem('@OMNIHUNTERtextSubject')}`)
        console.log(`@OMNIHUNTERtextSubjectTitle: ${localStorage.getItem('@OMNIHUNTERtextSubjectTitle')}`)
        console.log(`textSearch:                  ${localStorage.getItem('textSearch')}`);
        console.log(`replyTo:                     ${localStorage.getItem('replyTo')}`)
        console.log(`channel:                     ${localStorage.getItem('channel')}`)
        this.authorized = true  
      }

    } catch (error) {
      this.authorized = false
    }
  }

  goContacts($event){
    document.location.reload();
    if ($event == 'contacts' || $event == undefined) {
      
      this.backEvent = this.backEvent == 'contacts' ? undefined : 'contacts'
      
    } else {
      this.backEvent = $event
    }
    
  }

  updateTemplateContext($event) {
    this.externalEvent = $event == 'contacts' || $event == undefined ? { backBtn: false } : { backBtn: true }

  }

  authorization(event) {
    
    if (event) {
      this.authorized = event
    } else {
      this.authorized = false;
    }
    
  }

  
}

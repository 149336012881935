import { Component, Input, EventEmitter, SimpleChanges, OnChanges, Output, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog } from '@angular/material';
import { ShareMessageComponent } from './share-message/share-message.component';
import { MessagesService } from 'src/app/services/messages.service';
import { catchError } from 'rxjs/operators';
import { ResourcesService } from 'src/app/services/resources.service';
import { faCar, faCheck, faShare, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SelectResourceComponent } from './select-resource/select-resource.component';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.styl', './message-item.component.scss' ]
})
export class MessageItemComponent implements OnChanges, OnDestroy {
  private subscription: Subscription = new Subscription()
  resourceIdentifier: any
  casualtyNumber: any
  comment: any
  paramsToShare: Array <any>
  @Input() selectedChat: any
  @Input() newResource: any
  @Input() message: any
  @Input() user: any
  @Input() resources: any
  @Input() contacts: any
  @Input() selectedVehicle: any = null;
  @Output() insertResource = new EventEmitter()
  @Output() updateMessage = new EventEmitter()
  
  //ícones
  faCar = faCar; 
  faCheck = faCheck; 
  faShare = faShare; 
  faTimes = faTimes;
  faTrashAlt = faTrashAlt;
  //ícones


  audioUrl: SafeResourceUrl;

  constructor( private utils: UtilsService, private dialog: MatDialog, private messagesApi: MessagesService,
    private resourceApi: ResourcesService, private sanitizer: DomSanitizer ) { }

    ngOnInit() {
      // console.log(this.message)
      // if (this.message.type == 'blob') {
      //   this.message.message = this.sanitizer.bypassSecurityTrustResourceUrl(this.message.message);
      // }
      //console.log(this.message);
    }

    ngOnChanges(changes: SimpleChanges): void {
    for (let attr in changes) {
      if (attr == 'resources' && changes.resources.currentValue) {
        this.resources = changes.resources.currentValue
        this.getResourceIdentifier()
      }

      if (attr == 'contacts' && changes.contacts.currentValue) {
        this.contacts = changes.contacts.currentValue
      }
      
      if (attr == 'newResource' && changes.newResource.currentValue) {
        // Se o identifier do newResource for pro resource da mensagem, altera o valor de resourceIdentifier
        if (this.message.idChannel && this.message.idChannel == changes.newResource.currentValue.id) {
          this.getResourceIdentifier();
        }
      }

      if (attr == 'selectedVehicle') {
        this.selectedVehicle = changes.selectedVehicle.currentValue
      }
    }
  }

    getResourceIdentifier() {
        if (this.message.idChannel) {
            let vehicle = this.resources.find(resource => resource.id == this.message.idChannel);

            if (vehicle) {
                let identifier = this.utils.identifier(vehicle)
                // Se bem está na lista e não possui identifier printa 'Carregando...' até receber a resposta do servidor
                this.resourceIdentifier = identifier ? `Bem ${identifier}` : null
                this.casualtyNumber     = vehicle.casualty_number;
                this.comment            =  vehicle.comment;
            } else if(this.message.channel) {
                this.resourceIdentifier = `Bem ${this.message.channel}`;
            } else {
                // Se bem não está na lista busca-o no servidor
                this.getIdentifierFromServer()
            }
        }
    }

  isReceivedMessage() {
    if (this.user.role == "master")
      return this.message.to != this.selectedChat.user1._id
    else
      return this.message.to == this.user._id || (this.message.toUser && this.message.toUser[0].company == this.user.company)
  }

  largeScreen() {
    return window.innerWidth > 500
  }
  
  goToResource(){
    if (this.user.role === 'locator') {
      let filterResourceByIdentifier = this.resourceIdentifier.split(' ')[1];
      let url = `${localStorage.getItem('referrer')}/resources/?text=${filterResourceByIdentifier}`;
      window.open(url)
    }
    
    //let response = await this.api.reqWithToken(`get-vehicle?id=${resource.id}`, null, null, 'POST');
    console.log(this.user)
    console.log(this.message)

    if (this.user.role === 'operator') {
      let tab = this.message.type == 'diligence' ? "?aba=7a" : "?aba=10a"
      
      let url = this.user.company ? `${localStorage.getItem('referrer')}/resources/${this.message.idChannel}${tab}` : null;
      window.open(url)
    }
  }

  goToComment() {
    if (this.user.role === 'locator') {
      let filterResourceByIdentifier = this.resourceIdentifier.split(' ')[1];
      let url = `${localStorage.getItem('referrer')}/resources/?text=${filterResourceByIdentifier}`;
      window.open(url)
    }
    
    //let response = await this.api.reqWithToken(`get-vehicle?id=${resource.id}`, null, null, 'POST');
    console.log(this.user)
    console.log(this.message)

    if (this.user.role === 'operator') {
      let url = this.user.company ? `${localStorage.getItem('referrer')}/comments/${this.comment.comment_id}` : null;
      window.open(url)
    }
  }

  isImage() {
    return ['', undefined, 'jpg', 'jpeg', 'png'].includes(this.message.type)
  }

  async seeFile(message) {
    
    if (message) {
      this.subscription.add(
        this.messagesApi.getFile(message).subscribe((nodeResult: any) => {
          console.log(nodeResult);
          window.open(nodeResult);      
        })
      )
    }


  }

  showShareMessageDialog() {
    try {
      let dialogRef = this.dialog.open(ShareMessageComponent, {
        width: '90vw',
        height: '95vh',
        data: {
          contacts: this.contacts
        }
      });
  
      dialogRef.componentInstance.onPositive.subscribe((data) => {
        this.paramsToShare = data;
        dialogRef.close();
        
        this.subscription.add(
          this.messagesApi.shareMessage(this.paramsToShare, this.message._id)
          .subscribe((result: any) => {
            console.log(result);
            
            alert('Mensagem compartilhada com sucesso')
          })
        )
      });

    } catch (error) {
      console.log(error);
      alert('Erro ao compartilhar mensagem')
    }
  }

  getIdentifierFromServer() {
    // Envia resource para ser inserido na lista dem Messages sem identifier
    this.insertResource.emit({ id: this.message.idChannel, hidden: true })

    // Quando servidor reponde com identifier, envia a resposta para Messages para atualizá-lo
    this.subscription.add(
      this.resourceApi.getIdentifier(this.message.idChannel).pipe(
        catchError(err => {     
          this.insertResource.emit({ id: this.message.idChannel, plate: 'Bem indisponível para este Usuário', hidden: true })
          console.log(err);
          return err
        })
        ).subscribe(result => {
          this.insertResource.emit({ id: this.message.idChannel, plate: result.identifier, hidden: true })
      })
    )
  }

  showSelectResource() {
    try {
      let showResources = this.resources.filter(resource => !resource.hidden)

      let dialogRef = this.dialog.open(SelectResourceComponent, {
        width: '90vw',
        height: '95vh',
        data: {
          resources: showResources,
          messageId: this.message._id
        }
      });
  
      dialogRef.componentInstance.onPositive.subscribe((data) => {
        if (data) {
          this.message = data.message
          this.updateMessage.emit(this.message)
  
          if (this.message.idChannel) {
            let vehicle = this.resources.find(resource => resource.id == this.message.idChannel)
    
            if (vehicle) 
              this.resourceIdentifier = `Bem ${this.utils.identifier(vehicle)}`
            else 
              this.getIdentifierFromServer()
          }
            
          //alert(`Mensagem categorizada para veículo ${this.resourceIdentifier}`)
        }

        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe(() => {
        this.resources.forEach(resource => resource.selected = false);
      })
    } catch (error) {
      console.log(error);
      alert('Erro ao categorizar veículo, tente novamente')
    }    
  }

  removeMessageResource() {
    try {
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Atenção',
          message: `Deseja realmente remover o ${this.resourceIdentifier} desta mensagem?`,
          positiveBtn: 'Remover',
          negativeBtn: 'Cancelar'
        }
      })

      dialogRef.componentInstance.onPositive.subscribe(data => {
        this.subscription.add(
          this.messagesApi.categorizeMessage(this.message._id, null).subscribe((result: any) => {
            if (result.status == 'error') 
              throw result.message
            else if (result.status == 'success') {
              this.message = result.message
              this.updateMessage.emit(this.message)
              //this.resourceIdentifier = null
            }
          })
        )
        
          console.log('before resourceMessage')
        this.messagesApi.resourceMessage(2 ,this.resourceIdentifier).subscribe((result: any) => {
          if (result.status == 'error') {
            console.log('errror')
          } else {
            console.log(result.idChannel);
          }
        })


        dialogRef.close()
      })
    } catch (error) {
      console.log(error);
      alert('Não foi possível remover categoria da mensagem, tente novamente')
    }
  } 

  canEraseMessage() {
    var now = new Date();
    var maxDateToDelete = new Date(this.message.sentAt);
    maxDateToDelete.setMinutes(maxDateToDelete.getMinutes() + 5);
    return (now < maxDateToDelete);
  }

  removeMessage() {
    
    try {
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Atenção',
          message: `Deseja realmente remover a mensagem?`,
          positiveBtn: 'Remover',
          negativeBtn: 'Cancelar'
        }
      })

      dialogRef.componentInstance.onPositive.subscribe(data => {
        this.subscription.add(
          this.messagesApi.removeMessage(this.message._id, null).subscribe((result: any) => {
            if (result.status == 'error') 
              throw result.message
            else if (result.status == 'success') {
              this.message.message = result.message //atualização da mensagem na tela
              this.updateMessage.emit(this.message)
              
            }
          })
          
        )
        
        dialogRef.close();

      })
    } catch (error) {
      console.log(error);
      alert('Não foi possível apagar a mensagem, tente novamente')
    }

  }

  removeFileMessage() {
    
    try {
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Atenção',
          message: `Deseja realmente remover o conteúdo da mensagem?`,
          positiveBtn: 'Remover',
          negativeBtn: 'Cancelar'
        }
      })

      dialogRef.componentInstance.onPositive.subscribe(data => {
        this.subscription.add(
          this.messagesApi.removeFileMessage(this.message._id, null).subscribe((result: any) => {
            if (result.status == 'error') 
              throw result.message
            else if (result.status == 'success') {
              this.message.message = result.message //atualização da mensagem na tela
              this.message.type = result.type
              this.updateMessage.emit(this.message)
              
            }
          })
          
        )
        
        dialogRef.close();

      })
    } catch (error) {
      console.log(error);
      alert('Não foi possível apagar o conteúdo da mensagem, tente novamente')
    }

  }

  availableResource() {
    if (this.resources) {
      let resource = this.resources.find(resource => resource.id == this.message.idChannel)    
      return !resource || resource.hidden ? false : true
    }
  }

  showMessage() {
    if (this.selectedVehicle) {
      return this.selectedVehicle.id == this.message.idChannel
    }

    return true
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    this.subscription.unsubscribe()
  }
}

import { Component, OnInit, Input, SimpleChanges, OnChanges, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { faPaperPlane, faCar, faMicrophone, faSmile, faCircle, faCheck, faWindowClose, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faGrin } from '@fortawesome/free-regular-svg-icons'
import { MessagesService } from '../services/messages.service';
import { UtilsService } from '../services/utils.service';
import { SocketioService } from '../services/socketio.service';
import { MatDialog } from '@angular/material';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { Subscription, interval, timer } from 'rxjs';
import { scan, takeWhile} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { WhatsappService } from './../services/whatsapp.service';
import { NotificationsService } from '../services/notifications.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//declare var webkitSpeechRecognition:any
declare var MediaRecorder: any;

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.styl', './messages.component.scss']
})
export class MessagesComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() selectedChat: any
  @Input() resources: any
  @Input() contacts: any
  @Input() selectedVehicle: any = null;
  @Input() receivedMessage: any
  @Output() sendedMessageEmitter = new EventEmitter()
  @Output() showVehiclesEmit = new EventEmitter()
  
  //when the microphone is recording, not is possible to select contacts or vehicles
  @Output() cancelSelects = new EventEmitter();
  
  private subscription: Subscription = new Subscription()
  newResource: any
  showAll: boolean = false
  currentResourceIdentifier: string
  ImageBase64: string;
  user: any = JSON.parse(localStorage.getItem('user'))
  messages: any
  defaultSuportOMNIHUNTERText: string = "Olá! Preciso de ajuda."


  ///////// icons ///////////////
  faPaperPlane = faPaperPlane; 
  faPaperclip = faPaperclip; 
  faCar = faCar; 
  faMicrophone = faMicrophone; 

  faSmile = faSmile;
  faGrin = faGrin;

  faCircle = faCircle;
  faCheck = faCheck;
  faWindowClose = faWindowClose;
  ///////// icons ///////////////


  textMessage: String = ''
  isChatUserOnline: String = ''
  textSubject: String = ''
  textSubjectTitle: String = ''
  height: any
  max_height = 15200;
  max_width = 25600;
  max_size = 20971520;
  
  fileSizeOk: boolean;
  fileTypeOk: boolean;

  reader: any


  /////// audio to text ////////
  webkitSpeechRecognition: any;
  audioMessage: any;  
  recognition: any;
  /////// audio to text ////////


  /////// audio //////
  private counterUp: Subscription;
  device: any;
  chunks: any;
  recorder: any;
  timeout: any;
  blob: any;
  readerResultAudio: any;
  contentAudio: any;
  clickMicrophone: number;
  secondstimeRecordMicrophone: any;
  addZeroOnSecondsTimeRecordMicrophone: any;
  minutesTimeRecordMicrophone: any;
  cancelMicRecord: any;
  ////// audio //////


  ////// emoji //////
  showEmoji: boolean;
  public textArea: string = '';
  public isEmojiPickerVisible: boolean;
  ////// emoji //////

  ////// whatsapp //////
  useWhatsApp: boolean;
  userWhatsApp: any;
  sendWhatsPhone: string; 
  sendWhatsMessage: string;
  successSendMessage: boolean;

  whatsappConfirmedNumber: boolean;
  whatsappPermission: boolean;
  whatsappPermissionAt: Date;

  //relativo ao envio de contato
  sendWhatsPhoneContactSupport: string;
  sendWhatsNameContactSupport: string;
  successSendContact: boolean;

  ////// whatsapp ///////

  modalOptions: NgbModalOptions;

  constructor( 
      private messagesApi: MessagesService, 
      private route: ActivatedRoute, 
      private utils: UtilsService, 
      private dialog: MatDialog,
      private modalService: NgbModal,
      private whatsappApi: WhatsappService,
      private notifications: NotificationsService,
      private loader: NgxUiLoaderService,
    ) 
    
    { 
      this.modalOptions = {
        backdrop:'static',
        backdropClass:'customBackdrop'
      }
    }
  
  ngOnInit() {

    // this.picker = new EmojiButton({
    //   position: 'right-start'
    // })

    // this.picker.on('emoji', function(emoji) {
    //   Input.value += emoji;
    // })

    this.textSubject = localStorage.getItem('@OMNIHUNTERtextSubject');
    this.textSubjectTitle = localStorage.getItem('@OMNIHUNTERtextSubjectTitle');
    localStorage.removeItem('@OMNIHUNTERtextSubject');
    localStorage.removeItem('@OMNIHUNTERtextSubjectTitle');
    
    if (this.textSubject) {
      this.textMessage = this.textSubject
    } else
      this.textMessage = ''

    this.userWhatsApp = JSON.parse(localStorage.getItem("user"));
    if (this.userWhatsApp.cpf == "33333333333") {
      this.useWhatsApp = true;
      this.sendWhatsPhone = "55"; 
      this.sendWhatsMessage = 'Prezado(a) usuário(a), essa é uma mensagem enviada pelo suporte da OmniHunter para verificar o seu número de whatsApp';
      this.successSendMessage = false;

      this.sendWhatsPhoneContactSupport = this.userWhatsApp.whatsappNumber;
      this.sendWhatsNameContactSupport = this.userWhatsApp.name;
      this.successSendContact = false;
    }

    console.log('component message')
    this.fileSizeOk = false;
    this.fileTypeOk = false;

    this.subscription.add(
      SocketioService.visualizeMessage.subscribe(data => {
        if (this.selectedChat.chatId != data.chatId){
          return
        }
        
        console.log(data);
        console.log(this.messages)
        if (this.messages) {
          this.messages.forEach(message => {
            message.visualized = true
          });
        }
      })
    )
    
    this.messages = []

    console.log(this.selectedChat)

    ///////////////////////////////////////////////////////////// audio /////////////////////////////////////////////////////////////////////
    this.clickMicrophone = 0;
    this.cancelMicRecord = false;
    
    ///////////////////////////////////////////////////////////// audio /////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////////////////////// emoji /////////////////////////////////////////////////////////////////////
    this.showEmoji = false;
    ///////////////////////////////////////////////////////////// emoji /////////////////////////////////////////////////////////////////////

    this.subscription.add(
      SocketioService.updateSenderScreen.subscribe(data => {
        console.log(data);
        console.log(this.selectedChat);
        this.getMessagesFromChat();
        
      })
    )


  }

  getIsUserOnline(): void {
    this.isChatUserOnline = null;

    if (this.selectedChat) {
      this.messagesApi.isUserOnline(this.selectedChat.userId).subscribe(async (result: any) => {
    
        this.isChatUserOnline = result.online;

      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {


    if (this.textSubject && !this.textMessage) {
      this.textMessage = this.textSubject
    }

    for (let attr in changes) {
      if (attr == 'selectedChat' && changes.selectedChat.currentValue) {
          
          this.selectedChat = changes.selectedChat.currentValue
          this.showAll = false
          this.getMessagesFromChat()

          this.messages = this.selectedChat.messages
          this.scrollToBottom()
        
          if(changes.selectedChat.currentValue) {
            this.selectedChat.selected = true;
            this.getIsUserOnline();
          }

          if(this.selectedChat.userCpf == "33333333333") {
            if(this.textSubject || !this.textMessage) {
              this.textMessage = this.defaultSuportOMNIHUNTERText;
            }
          } else {
            if(this.textMessage == this.defaultSuportOMNIHUNTERText) {
              this.textMessage = this.textSubject || "";
            }
          }
      }

      if (attr == 'resources') {
        
        this.resources = changes.resources.currentValue
        this.scrollToBottom()
      }

      if (attr == 'selectedVehicle') {

          this.selectedVehicle = changes.selectedVehicle.currentValue
          this.processMessagesToShow()
          this.scrollToBottom()
        
      }
      
      if (attr == 'receivedMessage' && changes.receivedMessage.currentValue) {
        if (this.selectedChat.chatId == changes.receivedMessage.currentValue.chat) {
          let messages = changes.receivedMessage.currentValue
          
          if (this.showAll) {
            messages.fromUser = [this.user]
            messages.toUser = [this.utils.getChatUser(this.selectedChat)]
          }
          if (this.messages)
          this.messages.push(changes.receivedMessage.currentValue)
          this.scrollToBottom();
          
        }
      }

      if (attr == 'contacts' && changes.contacts.currentValue) {
          
          this.contacts = changes.contacts.currentValue
        
      }

      console.log(`MessagesComponent:ngOnChanges: ${attr}`);

      if(attr == 'clickedOnSupportBTN') {
        console.log(`clickedOnSupportBTN`);
        this.textMessage = "Olá! Preciso de ajuda.";
      }
    }

    this.subscription.add(
      SocketioService.socketContactStateChanged.subscribe(data => {
        this.getIsUserOnline();
      })
    )
  
  }

  /////////////////////////////////////////////////////// whatsApp ///////////////////////////////////////////////////
  
  //Para obter o contato assim que o contato é selecionado
  getConfirmWhatsApp(content) {
    this.loader.start();

    if (this.selectedChat.role == 'locator') {
      this.sendWhatsMessage = `Olá *${this.selectedChat.name}*, essa é uma mensagem enviada pelo Suporte da OMNIHUNTER para verificar o seu número de whatsApp.\n\n*Autorize o recebimento de notificações no seu WhatsApp*:\n👉 https://agente.omnihunter.net/whatsapp`;
    } else {
      this.sendWhatsMessage = `Olá *${this.selectedChat.name}*, essa é uma mensagem enviada pelo Suporte da OMNIHUNTER para verificar o seu número de whatsApp`;
    }
    
    this.whatsappApi.getConfirmWhatsApp(this.selectedChat.role, this.selectedChat.managerId, this.userWhatsApp.token).subscribe((result: any) => {
      this.loader.stop();
      if (result.status == 'success') {
        this.sendWhatsPhone = result.data.whatsapp_number;
        this.whatsappConfirmedNumber = result.data.confirmed_number;
        this.whatsappPermission = result.data.whatsapp_permission;
        this.whatsappPermissionAt = result.data.whatsapp_permission_at;
      }
    })

    this.modalService.open(content, this.modalOptions).result.then((result) => {
         
    }, (reason) => {
        console.log(reason);
    });
  }

  //Para confirmar que o número de whatsapp é mesmo do usuário
  confirmWhatsappNumberAfterTest(phone) {
    this.loader.start();
    this.whatsappApi.confirmWhatsappNumberAfterTest(this.selectedChat.role, this.selectedChat.managerId, this.userWhatsApp.token, phone).subscribe((result: any) => {
      this.loader.stop();
      if (result.status == 'success') {
        this.sendWhatsPhone = result.data.whatsapp_number;
        this.whatsappConfirmedNumber = result.data.confirmed_number;
        this.whatsappPermission = result.data.whatsapp_permission;
        this.whatsappPermissionAt = result.data.whatsapp_permission_at;

        this.notifications.showNotification('top', 'right', 2, 'O número de whatsapp foi salvo com sucesso!', 4000);
      } else {
        this.notifications.showNotification('top', 'right', 4, 'Não foi possível salvar o número de whatsapp!', 4000);
      }
    })
  }

  //envia mensagem
  sendWhatsByPhone() {

    try {
      this.loader.start()
      this.whatsappApi.sendMessagewhatsapp(this.sendWhatsPhone, this.sendWhatsMessage).subscribe((result: any) => {
        let response = result.message;
        this.loader.stop()
        if (response == 'Mensagem Enviada') {
          this.successSendMessage = true;
          this.notifications.showNotification('top', 'right', 2, 'Mensagem enviada com sucesso!', 4000);
        } else {
          this.notifications.showNotification('top', 'right', 4, 'Erro ao enviar mensagem, tente novamente!', 4000);
        }

      })
    } catch (error) {
      console.log(error)
      this.loader.stop()
      this.notifications.showNotification('top', 'right', 4, 'Erro ao enviar mensagem, pode ser algum problema de autenticação!', 4000);
    }

  }

  sendContactWhatsByPhone() {

    try {
      this.loader.start()
      this.whatsappApi.sendContactWhatsapp(this.sendWhatsPhone, this.sendWhatsPhoneContactSupport, this.sendWhatsNameContactSupport).subscribe((result: any) => {
        let response = result.message;
        this.loader.stop()
        if (response == 'Contato Enviado') {
          this.notifications.showNotification('top', 'right', 2, 'Contato enviado com sucesso!', 4000);
        } else {
          this.notifications.showNotification('top', 'right', 4, 'Erro ao enviar contato, tente novamente!', 4000);
        }

      })
    } catch (error) {
      this.loader.stop()
      console.log(error)
      this.notifications.showNotification('top', 'right', 4, 'Erro ao enviar contato! tente novamente!!', 4000);
    }

  }

  newMessage() {
    this.successSendMessage = false;
  }

  /////////////////////////////////////////////////////// whatsApp ///////////////////////////////////////////////////





  /////////////////////////////////////////////////////// emojis //////////////////////////////////////////////////////

  public addEmoji(event) {
    this.textMessage = `${this.textMessage}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  showEmojiTable() {
    
    this.showEmoji = !this.showEmoji;
    
  }

  hiddenEmojiTable() {

    this.showEmoji = false;

  }

  getEmoji(event) {
    
    this.textMessage = this.textMessage + event.target.innerHTML;

  }
  /////////////////////////////////////////////////////// smile //////////////////////////////////////////////////////




  //////////////////////////////////////////////////////// display timer audio ///////////////////////////////////////////////
  startTimer() {

    this.secondstimeRecordMicrophone = 0;
    this.addZeroOnSecondsTimeRecordMicrophone = '0';
    this.minutesTimeRecordMicrophone = 0;

    this.counterUp = interval(1000).subscribe((onesecond) => { this.addOneSecond(); });

  }

  addOneSecond() {
    this.secondstimeRecordMicrophone++;

    if (this.secondstimeRecordMicrophone > 9) {
      this.addZeroOnSecondsTimeRecordMicrophone = null;
    }

    if (this.secondstimeRecordMicrophone == 60) {
      this.secondstimeRecordMicrophone = 0;
      this.minutesTimeRecordMicrophone++;
      this.addZeroOnSecondsTimeRecordMicrophone = '0'
    }
  }

  stopTimer() {
    this.counterUp.unsubscribe();
    this.secondstimeRecordMicrophone = 0;
    this.minutesTimeRecordMicrophone = 0;
    this.addZeroOnSecondsTimeRecordMicrophone = null;
  }
  ///////////////////////////////////////////////////// display timer audio ////////////////////////////////////////////////
   


  // No primeiro click inicia-se a gravar, no segundo para, e segue alternando
  clickOnMicrophone() {
    this.clickMicrophone++;
    
    if ((this.clickMicrophone % 2) == 1) {
      this.record();
      this.clickMicrophone = 1;
      this.cancelSelects.emit(1)
    } else {
      this.stopRecord()
      this.clickMicrophone = 0;
      this.cancelSelects.emit(0);
    }

    console.log(this.clickMicrophone)

  }

  saveRecordMobile() {
      this.stopRecord()
      this.clickMicrophone = 0;
      this.cancelSelects.emit(0);
  }


  cancelRecord() {
    this.cancelSelects.emit(0);
    this.cancelMicRecord = true;
  }

  cancelMobileRecord() {
    this.stopRecord();
    this.cancelSelects.emit(0);
    this.cancelMicRecord = true;
    this.clickMicrophone = 0;
    this.stopTimer();
  }



  /////////////////////////////////////////////////////// audio by microphone /////////////////////////////////////////

  record() {
    this.startTimer();
    console.log(' rec microphone')
    
    this.device = navigator.mediaDevices.getUserMedia({ audio: true })
    this.chunks = [];
    this.recorder;

    this.device.then(stream => {
            
        this.recorder = new MediaRecorder(stream);
            
        setTimeout(() => {
          this.recorder.start()
          console.log('start')
        }, 100)

        this.recorder.ondataavailable = async e => {
            
            this.chunks.push(e.data);
            
        }

        this.recorder.onstop = async e => {
                  
            this.blob = new Blob(this.chunks, {type: 'application/ogg'});
            const reader = new FileReader();
            reader.readAsDataURL(this.blob);
            
            reader.onloadend = async () => {
               
              //content to server
              this.readerResultAudio = await reader.result;
              console.log(this.readerResultAudio);
              this.contentAudio = await this.messageBase('blob', ''); 
              this.contentAudio.message = this.readerResultAudio;        
              console.log(this.contentAudio);
              
              //se o audio for não for cancelado e haver algum conteudo gravado, o audio sera enviado para a S3 
              if (!this.cancelMicRecord) {

                if (this.contentAudio.message) {
                  this.subscription.add(
                    this.messagesApi.sendFile(this.contentAudio, null, null).subscribe((nodeResult: any) => {
                      this.messageSentProcess(nodeResult)
                    }, (error) => {
                      console.log(error.message);
                      
                      let dialogRef = this.dialog.open(AlertDialogComponent, {
                        data: {
                          title: 'Atenção',
                          message: `Não foi possível enviar o áudio, tente novamente!.`,
                          positiveBtn: 'Fechar',
                        }
                      })
                
                      dialogRef.componentInstance.onPositive.subscribe(data => {
                        dialogRef.close()
                      })
                    })
                  )
                }
                //se o audio for cancelado
              } else if (this.cancelMicRecord) {
                
                  this.contentAudio = null;
                  this.chunks = [];
                  this.cancelMicRecord = false;

              }
          
            }
            
            this.textMessage = '';           
        }
    });

  }

  stopRecord() {
    this.stopTimer()
    console.log('stop recording')
    
    this.recorder.stop(); 
    
    // clearInterval(this.timeout);
    
    
  }

  /////////////////////////////////////////////////////// audio by microphone /////////////////////////////////////////





  /////////////////////////////////////////////////////// audio to text ////////////////////////////////////////////////////////////////////////////////


  // async audioToText() {
  //   this.recognition = await this.processAudioToText() 
  //   console.log(this.recognition)
  //   this.textMessage = this.recognition.audioMessage;
  //   console.log(this.textMessage)
  // }



  // processAudioToText() {
  //           //The names of others libs to transcript audio           
  //           //var recognition = new (window.SpeechRecognition || window['webkitSpeechRecognition'] || window['mozSpeechRecognition'] || window['msSpeechRecognition']();
            
  //           if ("webkitSpeechRecognition" in window) {

  //             const recognition = new webkitSpeechRecognition();
              
  //             recognition.interimResults = true;
  //             recognition.lang = "pt-BR";
  //             recognition.continuous = true;
              
  //             recognition.start();
  //             console.log(recognition)
              
  //             // This event happens when you talk in the microphone
  //             recognition.onresult = function(event) {
  //               for (let i = event.resultIndex; i < event.results.length; i++) {
  //                 if (event.results[i].isFinal) {
  //                   // Here you can get the string of what you told
  //                   const content = event.results[i][0].transcript.trim();
  //                   this.audioTexto = content;
  //                   this.textMessage = this.audioTexto;
  //                   recognition.stop()
                    
  //                 }
  //               }
  //             };
              
  //             recognition.onend = function(event) {
  //               console.log('aqui termina')
  //               this.textMessage = this.audioTexto;
  //               document.getElementById("textaudio").value = this.textMessage;
  //             }

  //             return recognition;

  //         } else {
            
  //           alert('Lamentamos, mas o seu navegador de internet não suporta a funcionalidade de áudio!')

  //           return
          
  //         }

          
  // }


  ////////////////////////////////////////////////////////// audio to text ///////////////////////////////////////////////////////////////////////////

  ngAfterViewInit(): void {
    this.scrollToBottom()
  }

  getMessagesFromChat() {
    try {
      this.subscription.add(
        this.messagesApi.getMessagesFromChat(this.selectedChat.chatId).subscribe(result => {
          this.messages = result.messages
          this.processMessagesToShow()
        })
      )
    } catch (error) {
      console.log(error);
      alert("Não foi possível obter as mensagens do chat, tente novamente!")
    }
  }

  processMessagesToShow() {
    if (!this.messages)
      return
    
    this.messages.forEach(message => {
      if(!this.selectedVehicle) {
        message.hidden = false
      }
      else {
        message.hidden = message.idChannel != this.selectedVehicle.id ? true : false
      }
    })
  }

  toShowMessages() {
    return this.messages.filter(message => !message.hidden)
  }

  showAllMessages() {
    try {
      if (this.showAll) {
        this.getMessagesFromChat()
        this.showAll = false
        this.scrollToBottom()
      } else {
        this.subscription.add(
          this.messagesApi.getMessagesFromCompany(this.selectedChat.chatId).subscribe(result => {
            this.messages = result.messages
            this.processMessagesToShow()
            this.showAll = true
            this.scrollToBottom()
          })
        )
      }
    } catch (error) {
      console.log(error);
      alert("Não foi possível obtér as mensagens do chat, tente novamente")
    }
  }

  scrollToBottom() {
    this.delay(250).then(() => {
      if (document.getElementById("chatList")) {
        document.getElementById("chatList").scrollTop = document.getElementById("chatList").scrollHeight
      }
    })
  }

  messageBase(type, text) {
    let toId = this.selectedChat.userId
    let sendAtDate = new Date()

    var idChannel = null;
    var channel   = null;

    if (this.selectedVehicle) {
      idChannel = this.selectedVehicle.id;
      channel = this.identifier(this.selectedVehicle.id);
    }
    
    var message = {
      appId: this.user.cpf + '_' + new Date().getTime(),
      from: this.user._id,
      to: toId,
      sentAt: sendAtDate.toISOString(),
      coordinates: '',
      chat: this.selectedChat.chatId,
      message: text || null,
      type: type || null,
      idChannel: idChannel,
      channel: channel,
      visualized: false
    }

    console.log(`messageBase: ${JSON.stringify(message)}`);

    return message;
  }

  async toSendMessage(){
    
    //////////////////////////////////////////// audio to text /////////////////////////////////////////////////////////////////////////////////
    
    //this.textMessage = document.getElementById("textaudio").value; 
    
    //////////////////////////////////////////// audio to text /////////////////////////////////////////////////////////////////////////////////
    
    if (this.textMessage.trim() == '')
      return

    this.textMessage = this.textMessage.trim()
    let message = await this.messageBase('text', this.textMessage)

    this.subscription.add(
      this.messagesApi.sendMessage(message).subscribe((result) => {
        this.messageSentProcess(result)
      }, (error) => {
        console.log(error.message);
        
        let dialogRef = this.dialog.open(AlertDialogComponent, {
          data: {
            title: 'Atenção',
            message: `Não foi possível enviar esta mensagem, verifique sua conexão com a internet.`,
            positiveBtn: 'Fechar',
          }
        })
  
        dialogRef.componentInstance.onPositive.subscribe(data => {
          dialogRef.close()
        })
      })
    )
    
    if (this.textSubject)
      this.textMessage = this.textSubject    
    else
      this.textMessage = ''   
  }

  async messageSentProcess(message) {
    console.log(message)
    await this.messages.push(message)
    await this.sendedMessageEmitter.emit(message)
    this.selectedChat.updatedAt = new Date()
    this.scrollToBottom()
    console.log('messageSentProcess')
  }

  onFileSelected(fileInput) {
    console.log('fileInput')
    let message = this.messageBase('', '')
    console.log(fileInput);
    
    const reader = new FileReader();
    
    reader.readAsDataURL(fileInput.target.files[0]);
    
    
    this.processImage(reader, message, fileInput)

   
    // if (fileInput.target.files && fileInput.target.files[0]) {
    //   if (fileInput.target.files[0].size > this.max_size) {
    //     console.log('Maximum size allowed is ' + this.max_size / 1000 + 'Mb');
    //     return false;
    //   }

    //   const reader = new FileReader();
    //   reader.readAsDataURL(fileInput.target.files[0]);
      
    //   this.processImage(reader, message, fileInput)
    // }
  }

  showResources() {
    this.showVehiclesEmit.emit()
  }

  largeScreen() {
    return window.innerWidth > 500
  }

  async verifyAllowTypeFile(fileType): Promise<boolean> {
    
    if (fileType == 'pdf') {
      return true
    } else if (fileType == 'jpeg') {
      return true
    } else if (fileType == 'jpg') {
      return true
    } else if (fileType == 'png') {
      return true
    } else {
      return false
    }

  }

  async verifyAllowSizeFile(fileSize): Promise<boolean> {

    if (fileSize < 4000000) {
      return true;
    } else {
      return false;
    }

  }

  async processImage(reader,  message, fileInput) {
    
    

    
    reader.onload = async (e: any) => {
      
      const image = new Image();
      image.src = e.target.result;

      message.message = e.target.result;
      
      let fileType = fileInput.target.files[0].type
      
      if (fileType.includes('image')) {
        fileType = fileType.replace('image\/', "")
      } else if (fileType.includes('pdf')) {
        fileType = fileType.replace('application\/', "")
      }
      

      this.fileTypeOk = await this.verifyAllowTypeFile(fileType);
      
      if (!this.fileTypeOk) {
        
          let dialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
              title: 'Atenção',
              message: `O arquivo anexado deve ser do tipo pdf, png, jpeg ou jpg`,
              negativeBtn: 'OK'
            }
          })
    
          dialogRef.componentInstance.onPositive.subscribe(data => {
            this.subscription.add()
            dialogRef.close()
          })

          fileInput.target.value = ''

        return
      }

      let fileSize = fileInput.target.files[0].size

      this.fileSizeOk = await this.verifyAllowSizeFile(fileSize)
      
      if (!this.fileSizeOk) {
        
        let dialogRef = this.dialog.open(AlertDialogComponent, {
          data: {
            title: 'Atenção',
            message: `O arquivo deve ser menor que 4MB`,
            negativeBtn: 'OK'
          }
        })
  
        dialogRef.componentInstance.onPositive.subscribe(data => {
          this.subscription.add()
          dialogRef.close()
        })

        fileInput.target.value = ''
      
        return
      }

      message.type = fileType
      let company = this.user.company ? this.user.company : this.selectedChat.company
      let locator_cpf = this.selectedChat.userCpf
      

      //it's categorized
      if (this.selectedVehicle && company) {
        message.idChannel = this.selectedVehicle.id
        console.log(message.idChannel)
        this.subscription.add(
          this.messagesApi.sendFile(message, company, locator_cpf).subscribe((nodeResult: any) => {
            this.messageSentProcess(nodeResult)
          })
        )//it's not categorized
      } else {
        this.subscription.add(
          this.messagesApi.sendFile(message, null, null).subscribe((nodeResult: any) => {
            this.messageSentProcess(nodeResult)
          })
        )
      }
    };

  }

  reduced(text) {
    let textSize = this.utils.largeScreen() ? 35 : 18
    return this.utils.reduced(text, textSize)
  }

  getUrlProfile(chat){
    let urls = this.utils.getProfileUrl()

    return this.user.role == "master" || chat.role == 'operator' ? urls.operatorImageUrl : urls.locatorImageUrl
  }
  
  getTooltipIcon(option) {
    if (option.role)
      return option.currentUserRole == "master" || option.role == 'operator' ? 'Escritório' : 'Localizador'

    else if (option.vehicle)
      return option.currentUserRole == "master" ? 'Usuário Master no chat' : `Estas mensagens estão vinculadas ao veículo ${this.identifier(this.selectedVehicle.id)}`
  }

  delay(ms: number): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    });
  }

  getPlaceholder() {
    if (this.user.role == 'master') 
      return 'Usuário master não interage no chat.'

    if (!this.activeChat())
      return 'Este usuário foi inativado, portanto não é possível trocar mensagens com ele.'
    else {
      return this.selectedVehicle ? `A mensagem será vinculada ao Bem ${ this.identifier(this.selectedVehicle.id) }` 
                                  : "Digite sua mensagem e clique no ícone para enviar"
    }
  }

  activeChat() {
    if (this.user.role == 'master') 
      return false

    return this.selectedChat.active !== false
  }

  getClipBtnClassConfig() {
    return this.largeScreen() ? 'col-1' : 'col-2'
  }

  getTextareaStyleConfig() {
    let largeScreen = {
      'padding-right': '-5%',
    }

    let notLargeScreen = {
      'padding': '2%',

    }

    return  this.largeScreen() ? largeScreen : notLargeScreen
  }

  getTextareaClassConfig() {
    if (!this.activeChat())
      return 'col-10'
    else {
      return this.largeScreen() ? 'col-7' : 'col-7'
    }
  }

  getSendBtnClassConfig() {
    return this.largeScreen() ? 'col-3' : 'col-1'
  }

  insertResource(newVehicle) {
    let index = this.resources.findIndex(resource => resource.id == newVehicle.id)
    
    if (index > 0) { // Se o bem está na lista de resources atualiza o identifier do bem
      this.resources[index].plate = newVehicle.plate // Envia aos MessagesItems o resource com identifier atualizado
      this.newResource = newVehicle

    } else { // Se bem não está na lista, insere-o
      this.resources.push(newVehicle)
    }
  }

  identifier(vehicleId) {
    try {
      let vehicle = this.resources.find(resource => resource.id == vehicleId)
      
      if (vehicle)
        return `${this.utils.identifier(vehicle)}`
      else
        return `Bem indisponível para este Usuário`

    } catch (error) {
      return `Bem indisponível para este Usuário`
    }
  }

  currentChatTitle() {
    var chatTitle = `<span>${this.selectedChat.name}</span>`;
    var extraData = "";

    if(this.selectedVehicle) {
        extraData += `Bem ${this.identifier(this.selectedVehicle.id)}`;

        if(this.selectedVehicle.casualty_number) {
            extraData += ` / Sinistro Nº ${this.selectedVehicle.casualty_number}`;
        }

        if(this.selectedVehicle.comment && this.selectedVehicle.comment.comment_data_id) {
            extraData += ` / Serviço ${this.selectedVehicle.comment.comment_data_id}`;
        }
    }

    if(extraData) {
        chatTitle += `<small>${extraData}</small>`;
    }

    return chatTitle;
  }

  getAllMessagesLabel() {
    return this.showAll ? 'Ver apenas suas mensagens para esse chat' : 'Ver todas as mensagens para esse chat'
  }

  updateMessage(newMessage) {
    let index = this.messages.findIndex(message => message._id == newMessage._id)
    this.messages[index] = newMessage
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    this.subscription.unsubscribe()
  }
}

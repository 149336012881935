import { environment } from 'src/environments/environment';
import { Component, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.styl']
})
export class FooterComponent implements OnChanges {
  @Input() externalEvent: any
  @Output() back = new EventEmitter()
  user: any
  productName: string = ''
  logoUrl = 'assets/images/ic_gray_hunter.png'
  showBackBtn: Boolean = false
  versionChat: any = environment.versionChat;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    for (let attr in changes) {
      if (attr == 'externalEvent' && changes.externalEvent.currentValue != undefined) {}
        this.showBackBtn = changes.externalEvent.currentValue.backBtn

      if (localStorage.getItem('user')) {
        this.user = JSON.parse(localStorage.getItem('user'))
        this.productName = JSON.parse(localStorage.getItem('product'))
      }
    }
  }

  getCompanyName() {
    return this.user && this.user.companyName ? `${this.user.companyName} : ` : ''
  }

  getProductName() {
    return this.productName
  }

  goBack() {
    this.back.emit()
  }
  
  largeScreen() {
    return window.innerWidth > 500
  }
}

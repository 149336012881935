import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  
  constructor( private httpClient: HttpClient ) { }


  authenticate(accessId:any): Observable<any> {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'accessId': accessId,
      })
    };

    console.log(accessId);

    let url = `${environment.nodeApi}/users?accessId=${accessId}?`
    
    return this.httpClient.get(url, httpOptions).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  
}

import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MessagesService } from '../services/messages.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { SocketioService } from '../services/socketio.service';
import { ResourcesService } from '../services/resources.service';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.styl', './main-content.component.scss']
})
export class MainContentComponent implements OnInit, OnChanges {
  user = JSON.parse(localStorage.getItem('user'))
  replyTo: any;
  idChannel: any;
  @Input() backEvent: any
  @Output() showingTemplate = new EventEmitter()
  updateContacts: any
  receivedMessage: any
  chats: any
  contacts: any
  resources: any
  loadingResources: Boolean = false
  selectedChat: any
  selectedVehicle: any = null;
  private subscription: Subscription = new Subscription()
  showMessages: boolean = true
  showContacts: boolean = true
  showVehicles: boolean = true
  mobileScreen: any

  availableSelectChat: any;
  availableSelectResource: any;

  hasFocus: Boolean = true;

  constructor( private messagesApi: MessagesService, private utils: UtilsService, private resourceApi: ResourcesService, private socketService: SocketioService) {
    this.socketService.connect()

    if (localStorage.getItem('replyTo')) {
      this.replyTo = localStorage.getItem('replyTo')
      this.idChannel = localStorage.getItem('channel')
      localStorage.removeItem('replyTo')
      localStorage.removeItem('channel')
    }
  }

  ngOnInit() {

    if (window.innerWidth > 500) {
      this.mobileScreen = false
    } else {
      this.mobileScreen = true
    }

    this.subscription.add(
      SocketioService.updateChat.subscribe(data => {
        console.log(data);
        console.log(this.selectedChat);
        if (this.selectedChat.chatId != data.message.chat) {
          if (this.user._id != data.message.from) {
            let updatedContact = this.contacts.find(contact => contact.chatId == data.message.chat)
            updatedContact.notVisualizedCount++
          }
        } else {
          if(this.hasFocus) {
            this.toVisualizeChatMessages()
          } else {
            let updatedContact = this.contacts.find(contact => contact.chatId == data.message.chat)
            updatedContact.notVisualizedCount++
          }
        }
  
        if (data.message.to == this.user._id || data.message.originalMessageId) {
          this.receivedMessage = data.message
        }
        console.log(data.message)
        // Atualiza a lista de contatos
        this.updateContacts = data.message
      })      
    )

    this.subscription.add(
      SocketioService.updateSenderScreen.subscribe(data => {
        console.log('ei')
        console.log(data)
        if (this.selectedChat.chatId != data.message.chat) {
          if (this.user._id != data.message.from) {
            let updatedContact = this.contacts.find(contact => contact.chatId == data.message.chat)
            updatedContact.notVisualizedCount++
          }
        } else {
          
          this.toVisualizeChatMessages()
        }
  
        if (data.message.to == this.user._id || data.message.originalMessageId) {
          this.receivedMessage = data.message
        }
  
        // Atualiza a lista de contatos
        this.updateContacts = data.message
        
      })
    )

    if (this.smallScreen() && !this.replyTo) {
        this.toShowContacts()
    } else {
      this.toShowAllComponents()
    }

    this.availableSelectChat = true;
    this.availableSelectResource = true;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for(let attr in changes) {
      if (attr == 'backEvent') {
        this.goBackTemplate()
      }
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event) {    
    this.hasFocus = true;
    console.log(`focus: ${this.hasFocus}`);

    this.toVisualizeChatMessages();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event) {
    this.hasFocus = false;
    console.log(`focus: ${this.hasFocus}`);
  }

  getChats() {
    this.subscription.add(
      this.messagesApi.getContacts()
      .subscribe((response) => {
        this.chats = response.chats
        this.processContactsList(this.chats)
  
        if (this.chats.length > 0) {
          if (this.replyTo) 
            this.responseChat()
          else if (!this.smallScreen()) {
              this.selectedChat = this.contacts[0]
              this.toVisualizeChatMessages()
              this.getVehicles(this.selectedChat)
          }
        }
      })
    )
  }

  // obtem apenas os contatos e id do chat para criar lista de contatos
  async processContactsList(chats) {
    let contactsFromChats = []

    chats.forEach(chat => {
      let contactAttr = {}
      
      if (this.user.role == 'master') {
        let username1 = chat.user1.name
        let username2 = chat.user2.name
        
        contactAttr = { chatId: chat._id, userId: chat.user1._id, role: chat.user1.role, user1: chat.user1, user2: chat.user2,
                        lastMessage: chat.lastMessage, name: `${username1} / ${username2}` }
      } else {
        let contact = this.utils.getChatUser(chat)
        var name;

        if (contact.companyName) {
          name = `${contact.name} - ${contact.companyName}`
        } else {
          name = contact.name;
        }
          
        contactAttr = {
                        chatId: chat._id,
                        userId: contact._id,
                        name: name,
                        role: contact.role,
                        lastMessage: chat.lastMessage,
                        userCpf: contact.cpf,
                        active: chat.active,
                        company: contact.company,
                        managerId: contact.managerId,
                        notVisualizedCount: chat.notVisualizedCount,
                        userCompanyName:    contact.companyName,
                        userName:           contact.name 
                      }
      }
      contactsFromChats.push(contactAttr)
    });
    
    this.contacts = contactsFromChats
  }

  toVisualizeChatMessages(){
    if (this.user.role != "master") {
      this.subscription.add(
        this.messagesApi.visualizeChatMessages(this.selectedChat.chatId).subscribe(result => {
          console.log(result);
          this.selectedChat.notVisualizedCount = 0
        })
      )
    }
  }

  sendedMessage($event) {
    this.updateContacts = $event
  }

  getVehicles(chat) {
    
    if (chat && this.user.role != 'master') {
      this.loadingResources = true;
      this.subscription.add(
        this.resourceApi.getResources(chat.userCpf).subscribe((result) => {
          result.resources.forEach(function(resource) {
            if(resource.comment) {
              resource.comment = JSON.parse(resource.comment);
            }
          });

          this.resources = result.resources;

          var openChatResourceId = localStorage.getItem('OpenChatResource');

          localStorage.removeItem('OpenChatResource');

          if(openChatResourceId) {
            localStorage.setItem('scrollToResourceId', openChatResourceId);

            console.log(`getVehicles: openChatResourceId = ${openChatResourceId}`);

            var vehicle = this.resources.find(resource => resource.id.toString() == openChatResourceId)
            this.toSelectVehicle(vehicle);
          } else {
            this.toSelectVehicle(null)
          }

          this.loadingResources = false;

        })
      )
    }

  }

  responseChat() {
    let chat = this.contacts.find(contact => contact.chatId === this.replyTo)
    this.toSelectChat(chat)
    this.subscription.add(
      this.resourceApi.getResources(this.selectedChat.userCpf).subscribe((result) => {
        this.resources = result.resources
        let resource = this.resources.find(resource => resource.id.toString() == this.idChannel)
        this.toSelectVehicle(resource)
      })
    )
  }

  toSelectChat(chat) {
    if (this.user.role != "master")
      this.getVehicles(chat)
    
    this.replyTo = null
    this.selectedChat = chat
    this.toSelectVehicle(null)

    if (this.smallScreen())
      this.toShowMessages()
  }

  toSelectVehicle(vehicle) {
    if (vehicle) {
      vehicle.selected = true
    }

    this.selectedVehicle = vehicle
  }
  
  smallScreen() {
    return this.utils.smallScreen()
  }

  toShowContacts() {
    this.getChats()
    this.selectedChat = false
    this.showContacts = true
    this.showMessages = false
    this.showVehicles = false
    this.showingTemplate.emit('contacts')
  }

  toShowMessages() {
    this.showContacts = false
    this.showMessages = true
    this.showVehicles = false
    this.showingTemplate.emit('messages')
  }

  toShowAllComponents() {
    this.getChats()
    this.showContacts = true
    this.showMessages = true
    this.showVehicles = true
  }

  toShowVehicles() {
    this.showContacts = false
    this.showMessages = false
    this.showVehicles = true
  }

  goBackTemplate(){  
    if (this.showVehicles) 
      this.toShowMessages()
    else if (this.showMessages) 
      this.toShowContacts()
    else 
      this.showingTemplate.emit('contacts')
  }

  // event = 1 >> it's recording
  // event = 0 >> it's not recording
  availableSelects(event) {
    
    if (event === 1) {
    
      this.availableSelectChat = false;
      this.availableSelectResource = false;
    
    } else if (event === 0) {

      this.availableSelectChat = true;
      this.availableSelectResource = true;
    
    }
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}

import { Component, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { MessagesService } from '../services/messages.service';
import { WhatsappService } from './../services/whatsapp.service';
import { AuthenticationService } from './../services/authentication.service';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { faSearch, faSignal } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.styl']
})
export class NavbarComponent implements OnChanges {
  @Input() externalEvent: any
  @Output() goToContacts = new EventEmitter()
  @Output() goResources = new EventEmitter()
  @Output() whatsappUser = new EventEmitter()
  //@Output() sendedBroadcast = new EventEmitter()
  user: any
  productName: string = ''
  logoUrl = 'assets/images/ic_gray_hunter.png'
  showBackBtn: Boolean = false

  textBroadcastMessage: String = ''
  
  modalOptions: NgbModalOptions;
  private subscription: Subscription = new Subscription()
  
  // Versão do Chat
  versionChat: any = environment.versionChat;
  versionWhatsapp: any = environment.versionWhatsapp;
  //

  // WhatsApp
  //myAngularxQrCode: string = null;
  //connected: boolean;
  //qrcodeUsed: boolean;
  numberGenerateQrCode: any;
  //sendWhatsPhone: string; 
  //sendWhatsMessage: string;
  //successSendMessage: boolean; 
  //

  //angularx-qrcode
  qrCode: any;
  needReadQrCode: any;

  // Whatsapp - verificar se usuário está habilitado para usar whatsapp
  userWhatsapp: any;
  whatsapp: any;
  //

  // Consulta para ver se usuário está logado
  userIsLogged: any;
  onlineUsersChat: any;
  faSearch = faSearch;
  faSignal = faSignal;
  
  constructor( 
    private modalService: NgbModal, 
    private messagesApi: MessagesService,
    private whatsappApi: WhatsappService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
  ) 
    
  {
      this.modalOptions = {
        backdrop:'static',
        backdropClass:'customBackdrop'
      }
  
  }

  async ngOnInit() {
    
    this.whatsapp = false
    this.userWhatsapp = JSON.parse(localStorage.getItem("user"));
    
    //this.myAngularxQrCode = '';
    //this.connected = false;
    //this.qrcodeUsed = false;
    
    if (this.userWhatsapp.name == "Suporte OMNIHUNTER") {
      
      this.whatsapp = true;
      this.qrCode = '';
      this.needReadQrCode = true;
      this.numberGenerateQrCode = 0;
      
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let attr in changes) {
      if (attr == 'externalEvent' && changes.externalEvent.currentValue != undefined) {}
        this.showBackBtn = changes.externalEvent.currentValue.backBtn

      if (localStorage.getItem('user')) {
        this.user = JSON.parse(localStorage.getItem('user'))
        this.productName = JSON.parse(localStorage.getItem('product'))
      }
    }
  }

  getCompanyName() {
    return this.user && this.user.companyName ? `${this.user.companyName} : ` : ''
  }

  getProductName() {
    return this.productName
  }

  goContacts() {
    this.goToContacts.emit()
  }

  goToResources() {
    this.goResources.emit()
  }
  
  largeScreen() {
    return window.innerWidth > 500
  }

  logoff() {

    localStorage.clear()

    document.location.reload(true);

  }

  // ********************************************* Início - WhatsApp Test lib Venom ************************************************** //


  userIsLoggedWhatsappTest() {
    this.whatsappApi.userIsLoggedWhatsapp().subscribe((result: any) => {
      if(result.message == "O usuário não está logado!") {
        this.userIsLogged = false;
      } else if (result.message == "O usuário está logado!") {
        this.userIsLogged = true;
      } 
    })
  }

  async onlineUsers(theOnlineUsers) {
  
    try {

      this.getOnlineUsers();
      
      this.modalService.open(theOnlineUsers, this.modalOptions).result.then((result) => { 
      }, (reason) => {
          console.log(reason);   
      });

    } catch (error) {

      console.log(error);

    }
  }

  async getOnlineUsers() {
    
    try {
      
      this.messagesApi.getOnlineUsers().subscribe(async (result: any) => {
        
        if (result) {
          console.log(result)
          await this.uniqueNamesArray(result.result);
          
        }

      })

    } catch (error) {

      console.log(error);

    }
  }

  async uniqueNamesArray(array) {
    
    let nameUsers = [];

    for(let i = 0; i < array.length; i++) {
      console.log(array[i].name);
      nameUsers.push(array[i].name);
    }
    
    console.log(nameUsers);
    
    this.onlineUsersChat = [...new Set(nameUsers)];

  }



  async openWhatsapp(whatsappVenom) {
    
    try {

      this.loader.start()

      this.whatsappApi.userIsLoggedWhatsapp().subscribe((result: any) => {   
        
        if(result.message == "O usuário não está logado!") {

          this.userIsLogged = false;

          this.modalService.open(whatsappVenom, this.modalOptions).result.then((result) => { 
          }, (reason) => {
              console.log(reason);   
          });

          this.whatsappApi.whatsappConnect().subscribe((result: any) => {    
            if (result.message == "Suporte OMNIHUNTER logado!") {
              this.needReadQrCode = false;
              this.userIsLogged = true;
            }    
          })

          this.numberGenerateQrCode = 0;

          this.loader.stop()

        } else if (result.message == "O usuário está logado!") {

            this.userIsLogged = true;

            this.modalService.open(whatsappVenom, this.modalOptions).result.then((result) => { 
            }, (reason) => {
                console.log(reason);
            });
            this.numberGenerateQrCode = 0;

            this.loader.stop()
        } 

      })

    } catch (error) {
      this.loader.stop()
      console.log('Problema ao verificar status do whatsapp: ' + error)
    }

  }

  async authenticateQrCode() {

      this.whatsappApi.getQrData().subscribe((result: any) => {
        if (result.dataQrCode) {
          this.qrCode = result.dataQrCode
          this.needReadQrCode = true;
          this.numberGenerateQrCode = result.attempts;
        }  
      })
    
  }
  

  // sendMessageWhatsappTest() {
  //   this.whatsappApi.sendMessagewhatsapp().subscribe((result: any) => {
  //     console.log('resultado da Send Message API Venom')
  //     console.log(result)
  //     console.log('resultado da Send Message API Venom')
  //   })
  // }

  // ********************************************* Fim - WhatsApp Test lib Venom ************************************************** //




  
  // ********************************************* Início - WhatsApp Test lib Baileys ************************************************** //


  //similar ao método connectWhatsapp. Quando o usuário seleciona o menu whatsapp, é aberto o modal e feita a conexão
  // openWhatsapp(content) {
     
  //   this.modalService.open(content, this.modalOptions).result.then((result) => {
         
  //   }, (reason) => {
  //       console.log(reason);
  //   });

  //   //this.connected = true;
  // }

  // // referente a conexão com a internet
  // isConnectedAtWhatsapp() {
    
  //   this.whatsappApi.isConnected().subscribe((result: any) => {
  //     if (result.status == 'success') {
  //       console.log('ok')
  //       this.connected = true;
  //       this.logged = true;
        
  //     } 

  //   })

  // }

  // PhoneConnectionWhatsappAfterLoginOrReloadPage() {
  //   this.whatsappApi.phoneConnectionWhatsappAfterLoginOrReloadPage().subscribe((result: any) => {
  //     console.log(result)
  //     if (result.status == 'connected') {
  //       this.connected = true;
  //       this.logged = true;
  //     } else if (result.status == 'disconnected') {
  //       this.connected = false;
  //       this.logged = false;
  //     } 

  //   })
  // }

  // //Depois da conexão a função de 30 em 30 segundos fica verificando a conexão com whatsapp
  // verifyPhoneConnectionWhatsapp() {
  //   this.whatsappApi.verifyPhoneConnectionWhatsapp().subscribe((result: any) => {
  //     if (result.status == 'success') {
  //       this.connected = false;
  //       this.logged = false;
  //     }
  //   })
  // }


  // //conectar ao whatsapp, similar a acessar web.whatsapp.com
  // connectWhatsapp() {
  //   this.whatsappApi.connectApi().subscribe((result: any) => {
  //     if (result.status == 'success') {
  //       this.logged = true;
  //       this.myAngularxQrCode = '';
  //       this.verifyPhoneConnectionWhatsapp();
  //     }
  //   })
  //   this.connected = true;
  // }

  
  // //cancelar conexão com whatsapp
  // closeConnectionWhatsapp() {
  //   this.whatsappApi.closeConnection().subscribe((result: any) => {
  //     if (result.status == 'success') {
  //       this.connected = false;
  //       this.logged = false;
  //       this.myAngularxQrCode = '';
  //     }
  //   })
  // }

  // //busca os dados para construir o qrcode para que o usuário faça login
  // getQrData() {
  //   this.whatsappApi.getQrData().subscribe((result: any) => {
  //     if (result.status == 'success') {
  //       this.qrcodeUsed = true;
  //       this.myAngularxQrCode = result.data;
  //     }
  //   })
  // }

  // //fazer logout da conta caso o usuário esteja logado
  // logoutWhatsapp() {
    
  //   this.whatsappApi.logout().subscribe((result: any) => {
  //     let response = result.status;
  //     if (response == 'success') {
  //       this.logged = false;
  //       this.connected = false;
  //     } 
  //   })
  // }

  // //envia mensagem para um telefone  
  // // sendWhatsByPhone() {
  // //   try {
  // //     this.whatsappApi.sendWhatsMessageByPhone(this.sendWhatsPhone, this.sendWhatsMessage).subscribe((result: any) => {
  // //       let response = result.message;
  // //       if (response == 'Mensagem Enviada') {
  // //         this.successSendMessage = true;
  // //       }
  // //       console.log(result)
  // //     })
  // //   } catch (error) {
  // //     console.log(error)
  // //   }
  // // }

  // cancel() {
  //   if (this.logged) {
  //     this.logoutWhatsapp();
  //   } else if (this.connected && !this.logged) {
  //     this.closeConnectionWhatsapp();
  //   } 
  //   console.log('cancel')
  // }

  // // newMessage() {
  // //   this.successSendMessage = false;
  // // }

  // async canUseWhatsapp(user) {

  //   this.whatsappApi.verifyIfCanUseWhatsapp(user).subscribe((result: any) => {
  //     if (result.status == 'success') {
  //       this.whatsapp = true;
  //     } else {
  //       this.whatsapp = false;
  //     }
  //     console.log('can use whatsapp')
  //   })

  // }


  // ********************************************* Fim - WhatsApp Test lib Baileys ************************************************** //



  toSendBroadcastMessage(content) {
    console.log('to send broad cast')
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      if (this.textBroadcastMessage.length < 1 && this.textBroadcastMessage.trim() != '') {
        return alert('Não foi possível enviar uma mensagem vazia. Tente novamente.')
      }

      let user = JSON.parse(localStorage.getItem('user'))
      var message = {
          from: user._id,
          message: this.textBroadcastMessage,
          type: 'text',
          coordinates: ''
      }
      
      this.subscription.add(
        this.messagesApi.sendBroadcastMessage(message).subscribe((result: any) => {
          this.textBroadcastMessage = ''
          alert('A mensagem está sendo enviada para todos os seus contatos no Chat, em breve o envio será concluído.')
          //this.sendedBroadcast.emit(true)
        })
      )
    }, (reason) => {
      console.log(reason);
    });
  }

  

  infoAbout(versionChat, versionWhatsapp) {
    
    try {
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Hunter Chat',
          message: `Sistema Omnihunter, ${versionChat}`,
          positiveBtn: 'Ok',
          
        }
      })

      dialogRef.componentInstance.onPositive.subscribe(() => { dialogRef.close();})

    } catch (error) {
      console.log(error);
      alert('Não foi possível obter informações sobre o sistema, tente novamente!')
    }
  }
  
  truncate(string: string, charNumber: number, charIfNull: string){
    if (string === null || string  === undefined)
      return charIfNull
    if (string.length < charNumber)
      return string
    else
      return string.slice(0, charNumber - 3) + '...'
  }

}

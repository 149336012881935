import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from '../services/utils.service';
import { ResourcesService } from '../services/resources.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.styl', './vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  @Input() resources: any
  @Input() loadingResources: Boolean = false
  @Input() selectedChat: any
  @Input() selectedVehicle: any = null;
  @Input() availableSelectResource: any
  @Output() selectedVehicleEmit = new EventEmitter()
  faSearch = faSearch
  vehicles: any
  searchingText: String = ''
  vehicleSelected: any;
  singleUser: String = ''
  resourceId: String = ''

  verifyUniqueUser: any
  
  constructor(private utils: UtilsService, private resourceApi: ResourcesService) { }

  async ngOnInit() {
    
    //Desactivate resource spinner when there aren't contacts 
    if(!this.vehicles) {
      this.vehicles = []
    }

    this.singleUser = localStorage.getItem('@OMNIHUNTERsingleUser');
    this.resourceId = localStorage.getItem('resource_id');

    //this.vehicles = this.resources;

    this.availableSelectResource = true;

    this.verifyUniqueUser = false;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
      for (let attr in changes) {
        console.log(`VehiclesComponent change (${attr}). Current vehicle.id: ${this.selectedVehicle ? this.selectedVehicle.id : ""}`);
      
      if (attr == 'resources') {

        // this.vehicles = this.resources
        
        if (changes.resources.currentValue) {
          this.vehicles = changes.resources.currentValue.filter(resource => resource.hidden != true)
          //console.log(this.vehicles)
          this.searchingText = ''
          await this.initializeWithVehicleSelected(this.vehicles)
        }

        if (this.resources) {
          // this.vehicles = this.resources
          await this.verifySingleUser();
          // if (!this.verifyUniqueUser) {
          //   await this.initializeWithVehicleSelected(this.vehicles)
          // }
        }
        

        // let singleUser = await this.verifySingleUser();
        // if (!singleUser) {
        //   await this.initializeWithVehicleSelected(this.vehicles)
        // }

      }

      

      if (attr == 'selectedVehicle') {
        // if (changes.selectedVehicle.currentValue) {
        //   this.selectedVehicle.selected = true
  
        // }

        if(this.selectedVehicle) {
          var scrollToResourceId = localStorage.getItem('scrollToResourceId');

          if(scrollToResourceId && scrollToResourceId == this.selectedVehicle.id) {
            localStorage.removeItem('scrollToResourceId');
            document.getElementById(`vehicle-${this.selectedVehicle.id}-div`).scrollIntoView();
          }
        }
      }

    }
  }

  async verifySingleUser() {
    if (this.singleUser && this.resourceId) {

      //console.log(this.vehicles)
      
      await this.vehicles.find(vehicle => { 
        if (vehicle.id == this.resourceId) {   
          localStorage.setItem('scrollToResourceId', vehicle.id);
          
          this.verifyUniqueUser = true;
          
          this.toSelectVehicle(vehicle);

          if (this.selectedVehicle )
            this.selectedVehicle.selected = true;
        }
      });
 
    }
    
    localStorage.removeItem('@OMNIHUNTERsingleUser');
    localStorage.removeItem('resource_id');
    // return this.verifyUniqueUser;
  }




    async initializeWithVehicleSelected(vehicles) {
        if(vehicles && vehicles.length > 0) {
            var resource_id = localStorage.getItem('resource_id')
            if (resource_id) {
                var chatCpfOpen = localStorage.getItem('chatCpfOpen');
                if(chatCpfOpen) {
                    localStorage.removeItem('resource_id')
                    localStorage.removeItem('chatCpfOpen')
                    console.log(`showSelectResource: ${resource_id}`);

                    let chatResourceSelected = vehicles.find(vehicle => { 
                        if (vehicle.id == resource_id) {
                            return vehicle;
                        }
                    })
                    
                    if(chatResourceSelected) {
                        var self = this;
                        setTimeout(function() {  
                            console.log(chatResourceSelected)
                            self.toSelectVehicle(chatResourceSelected)
                        }, 1000);
                    }
                    else {
                        console.log(`showSelectResource: bem não encontrado.`);  
                    }    
                }
                else {
                    console.log(`showSelectResource: chatCpf ainda não aberto.`);  
                }    
            } else {
                console.log(`showSelectResource: SEM resource_id`);
            }
        }
    }

  toSelectVehicle(vehicle) {
    console.log(`toSelectVehicle: availableSelectResource = ${this.availableSelectResource}`);
    if(this.availableSelectResource) {

      if (vehicle.selected) {
        vehicle.selected = false
        this.selectedVehicleEmit.emit(null)
      } else {
        this.vehicles.forEach(vehicle => {
          vehicle.selected = false
        });
        this.selectedVehicleEmit.emit(vehicle)
        vehicle.selected = true
      }

      this.utils.scrollToMessages();
    }
  }

  filterVehicleById(id) {
    this.vehicles = this.resources.filter(item => item.id == id);
  }


  filterVehicle($event) {
    if (this.searchingText != '') {
      this.vehicles = this.resources.filter(vehicle => this.filterByName(vehicle, this.searchingText))
    } else {
      this.vehicles = this.resources
    }
  }

  filterByName(vehicle, text) {
    let identifiers = this.identifiers(vehicle)

    if (identifiers[0] != null && identifiers[0].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[1] != null && identifiers[1].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[2] != null && identifiers[2].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[3] != null && identifiers[3].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[4] != null && identifiers[4].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[5] != null && identifiers[5].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[6] != null && identifiers[6].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[7] != null && identifiers[7].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[8] != null && identifiers[8].includes(this.normalizeText(text))) {
      return true;
    }

    var normalizedOwner = this.normalizeText(vehicle.owner);

    if (normalizedOwner != null && normalizedOwner.includes(this.normalizeText(text))) {
      return true;
    }

    var normalizedCasualtyNumber = this.normalizeText(vehicle.casualty_number);

    if (normalizedCasualtyNumber != null && normalizedCasualtyNumber.includes(this.normalizeText(text))) {
      return true;
    }

    if(vehicle.comment) {
      var normalizedCommentDataId = this.normalizeText(new String(vehicle.comment.comment_data_id));

      if (normalizedCommentDataId != null && normalizedCommentDataId.includes(this.normalizeText(text))) {
        return true;
      }
    }

  }

  identifiers(vehicle) {
    return [
      this.normalizeText(vehicle.num_contract), 
      this.normalizeText(vehicle.manufacter), 
      this.normalizeText(vehicle.model),
      this.normalizeText(vehicle.chassi), 
      this.normalizeText(vehicle.plate), 
      this.normalizeText(vehicle.renavam),
      this.normalizeText(vehicle.serial_number), 
      this.normalizeText(vehicle.machine_number),
      this.normalizeText(vehicle.proposal_code),
    ]
  }

  normalizeText = (text) => {
    if (!text) {
      return null
    }

    var r = text.toLowerCase();
    r = r.replace(new RegExp("\\s", 'g'), "");
    r = r.replace(new RegExp("[àáâãäå]", 'g'), "a");
    r = r.replace(new RegExp("æ", 'g'), "ae");
    r = r.replace(new RegExp("ç", 'g'), "c");
    r = r.replace(new RegExp("[èéêë]", 'g'), "e");
    r = r.replace(new RegExp("[ìíîï]", 'g'), "i");
    r = r.replace(new RegExp("ñ", 'g'), "n");
    r = r.replace(new RegExp("[òóôõö]", 'g'), "o");
    r = r.replace(new RegExp("œ", 'g'), "oe");
    r = r.replace(new RegExp("[ùúûü]", 'g'), "u");
    r = r.replace(new RegExp("[ýÿ]", 'g'), "y");
    r = r.replace(new RegExp("\\W", 'g'), "");
    return r;
  }

  identifier(vehicle) {
    return this.utils.identifier(vehicle)
  }

  largeScreen() {
    return this.utils.largeScreen()
  }

}

import { AlertDialogComponent } from './../../../alert-dialog/alert-dialog.component';
import { Component, Input, OnInit, EventEmitter, Inject, Output, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MessagesService } from 'src/app/services/messages.service';
import { UtilsService } from 'src/app/services/utils.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { catchError } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-select-resource',
  templateUrl: './select-resource.component.html',
  styleUrls: ['./select-resource.component.styl']
})
export class SelectResourceComponent implements OnDestroy {
  resources: any
  messageId: number
  resourceId: number
  vehicles: any
  selectedResourceId: any
  faSearch = faSearch
  searchingText: String = ''
  onPositive = new EventEmitter()

  @Input() message: any
  @Output() updateMessage = new EventEmitter()

  private subscription: Subscription = new Subscription()
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private messagesApi: MessagesService, private utils: UtilsService ) {
    this.resources = data.resources
    this.vehicles = this.resources
    console.log('PARAMS: ', data);
    
    this.messageId = data.messageId
  }

  categorizeMessage() {
    
    let identifierResource = this.data.resources.filter(resource => resource.id == this.selectedResourceId )
    
    try {
      let dialogRef = this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Atenção',
          message: `Deseja realmente categorizar o bem ${identifierResource[0].plate}?`,
          positiveBtn: 'Sim',
          negativeBtn: 'Cancelar'
        }
      })

      dialogRef.componentInstance.onPositive.subscribe(data => {
        this.subscription.add(
          this.messagesApi.categorizeMessage(this.messageId, this.selectedResourceId).subscribe((result: any) => {
            if (result.status == 'error') 
              throw result.message
            else if (result.status == 'success') {   
              this.message = result.message
              this.updateMessage.emit(this.message)
              this.selectedResourceId = null
              this.onPositive.emit(result)
            }
          })
        )
        dialogRef.close()
      })
    } catch (error) {
      console.log(error);
      alert('Não foi possível categorizar a mensagem, tente novamente')
      this.onPositive.emit(null);
    }

    // this.subscription.add(
    //   this.messagesApi.categorizeMessage(this.messageId, this.selectedResourceId).pipe(
    //     catchError(err => {
    //       console.log(err);
    //       alert('Não foi possível categorizar a mensagem, tente novamente')
    //       this.onPositive.emit(null)
          
    //       return  err
    //     })
    //   ).subscribe((result: any) => {
    //     this.onPositive.emit(result)
    //   })
    // )
  }

  toSelectResource(resource) {
    this.resources.forEach(resource => resource.selected = false)

    if (resource.id == this.selectedResourceId) {
      this.selectedResourceId = null
    } else {
      this.selectedResourceId = resource.id
    }
  }

  filterVehicle($event) {
    if (this.searchingText != '') {
      this.vehicles = this.resources.filter(vehicle => this.filterByName(vehicle, this.searchingText))
    } else {
      this.vehicles = this.resources
    }
  }

  filterByName(vehicle, text) {
    let identifiers = this.identifiers(vehicle)

    if (identifiers[0] != null && identifiers[0].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[1] != null && identifiers[1].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[2] != null && identifiers[2].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[3] != null && identifiers[3].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[4] != null && identifiers[4].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[5] != null && identifiers[5].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[6] != null && identifiers[5].includes(this.normalizeText(text))) {
      return true;
    }
    if (identifiers[7] != null && identifiers[5].includes(this.normalizeText(text))) {
      return true;
    }
  }

  identifiers(vehicle) {
    return [this.normalizeText(vehicle.num_contract), this.normalizeText(vehicle.manufacter), this.normalizeText(vehicle.model),
      this.normalizeText(vehicle.chassi), this.normalizeText(vehicle.plate), this.normalizeText(vehicle.renavam),
      this.normalizeText(vehicle.serial_number), this.normalizeText(vehicle.machine_number)]
  }

  normalizeText = (text) => {
    if (!text) {
      return null
    }

    var r = text.toLowerCase();
    r = r.replace(new RegExp("\\s", 'g'), "");
    r = r.replace(new RegExp("[àáâãäå]", 'g'), "a");
    r = r.replace(new RegExp("æ", 'g'), "ae");
    r = r.replace(new RegExp("ç", 'g'), "c");
    r = r.replace(new RegExp("[èéêë]", 'g'), "e");
    r = r.replace(new RegExp("[ìíîï]", 'g'), "i");
    r = r.replace(new RegExp("ñ", 'g'), "n");
    r = r.replace(new RegExp("[òóôõö]", 'g'), "o");
    r = r.replace(new RegExp("œ", 'g'), "oe");
    r = r.replace(new RegExp("[ùúûü]", 'g'), "u");
    r = r.replace(new RegExp("[ýÿ]", 'g'), "y");
    r = r.replace(new RegExp("\\W", 'g'), "");
    return r;
  }

  identifier(resource) {
    return this.utils.identifier(resource)
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    this.subscription.unsubscribe()
  }
}

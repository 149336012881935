import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  httpOptions = {
    headers: new HttpHeaders({
      'accessToken': localStorage.getItem('token'),
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    })
  };
  
  httpFileMessageOptions = {
    headers: this.httpOptions.headers,
    reportProgress: true,
    observ: 'events'
  };

  constructor( private httpClient: HttpClient ) { }

  getContacts(): Observable<any> {
    let url = `${environment.nodeApi}/chats?dateLastSync=null`
    
    return this.httpClient.get(url, this.httpOptions).pipe(
      catchError(err => {
        console.log(err)  
        throw err
      })
    )
  }

  getOnlineUsers(): Observable<any> {
    let url = `${environment.nodeApi}/getOnlineUsers`
    
    return this.httpClient.get(url, this.httpOptions).pipe(
      catchError(err => {
        console.log(err)  
        throw err
      })
    )
  }

  isUserOnline(userId): Observable<any> {
    let url = `${environment.nodeApi}/users/${userId}/isOnline`

    return this.httpClient.get(url, this.httpOptions);
  }

  getMessagesFromChat(chatId: any): Observable<any> {
    let url = `${environment.nodeApi}/chats/${chatId}/messages`
    return this.httpClient.get(url, this.httpOptions)
  }

  getMessagesFromCompany(userChatId: any): Observable<any> {
    let url = `${environment.nodeApi}/chats/${userChatId}/messages/allFromCompany`
    return this.httpClient.get(url, this.httpOptions)
  }

  sendMessage(message): Observable<any> {    
    let url = `${environment.nodeApi}/messages`
    
    return this.httpClient.post(url,{ message: message } , this.httpOptions).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  sendFile(message, company, locatorCpf): Observable<any> {
    let url = `${environment.nodeApi}/messages/file`
    let body = { message: message, company: company, cpf: locatorCpf }

    return this.httpClient.post(url, body, this.httpFileMessageOptions).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  sendAudioFile(fileAudio): Observable<any> {
    let url = `${environment.nodeApi}/messages/audio-file`
    let body = { fileAudio }

    return this.httpClient.post(url, body, this.httpFileMessageOptions).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  getFile(message): Observable<any> {
    console.log(message)
    let url = `${environment.nodeApi}/messages/getfile`
    let body = { message: message}

    return this.httpClient.post(url, body, this.httpFileMessageOptions ).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  sendBroadcastMessage(message): Observable<any> {
    let url = `${environment.nodeApi}/messages/broadcast`

    return this.httpClient.post(url, { message: message }, this.httpOptions).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  shareMessage(paramsToShare, chatMessageId): Observable<any> {
    let url = `${environment.nodeApi}/messages/share`
    var content = { chatMessageId: chatMessageId, shareParams: paramsToShare }
    
    return this.httpClient.post(url, content, this.httpOptions).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  categorizeMessage(messageId, resourceId): Observable<any> {
    let url = `${environment.nodeApi}/messages/categorize`
    var content = { chatMessageId: messageId, resourceId: resourceId }
    
    return this.httpClient.post(url, content, this.httpOptions)
  }

  removeMessage(messageId, resourceId): Observable<any> {
    let url = `${environment.nodeApi}/messages/removeMessage`
    let content = { messageId: messageId, resourceId: resourceId }

    return this.httpClient.post(url, content, this.httpOptions)
  }

  removeFileMessage(messageId, resourceId): Observable<any> {
    let url = `${environment.nodeApi}/messages/removeFileMessage`
    let content = { messageId: messageId, resourceId: resourceId }

    return this.httpClient.post(url, content, this.httpOptions)
  }

  resourceMessage(company_id, resource_id) : Observable<any> {
    let url = `${environment.nodeApi}/messages/resource`
    console.log(url);
    console.log(company_id)
    console.log(resource_id)
    var content = { company_id: company_id, resource_id: resource_id}
    return this.httpClient.post(url, content, this.httpOptions);
  }
  
  visualizeChatMessages(chat_id): Observable<any> {
    let url = `${environment.nodeApi}/messages?chat=${chat_id}`
    
    return this.httpClient.put(url, null, this.httpOptions).pipe(
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }
}
 
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  currentUser: any = JSON.parse(localStorage.getItem('user'))
  omniSupportCpf: "33333333333";

  constructor() { }
  
  largeScreen() {
    return window.innerWidth > 500
  }

  smallScreen() {
    return window.innerWidth < 500
  }

  reduced(string, size) {
    if (string === null || string  === undefined)
      return '-'
    if (string.length < size)
      return string
    else
      return string.slice(0, size - 3) + '...'
  }

  getChatUser(chat) {
    return chat.user1._id == this.currentUser._id ? chat.user2 : chat.user1
  }

  identifier(vehicle) {
    if (!vehicle)
      return null
    if (vehicle.proposal_code) {
      return vehicle.proposal_code
    } else if (vehicle.plate) {
      return vehicle.plate
    } else if (vehicle.chassi) {
      return vehicle.chassi
    } else if (vehicle.serial_number) {
      return vehicle.serial_number
    } else if (vehicle.machine_number) {
      return vehicle.machine_number
    } else if (vehicle.renavam) {
      return vehicle.renavam
    } else if (vehicle.model) {
      return vehicle.model
    }
  }
  
  getProfileUrl(){
    return {
      operatorImageUrl: "/assets/images/profile-image-png-14-original.png",
      locatorImageUrl: "/assets/images/agent-image.png"
    }
  }

  scrollToFlowPage(flow: String) {
    document.getElementsByClassName("flow-container")[0].scroll(
      {
        left: document.getElementById(`${flow}-flow`).offsetLeft, behavior: 'smooth'
      }
    );
  }

  scrollToMessages() {
    this.scrollToFlowPage("messages");
  }

  scrollToContacts() {
    this.scrollToFlowPage("contacts");
  }

  scrollToVehicles() {
    this.scrollToFlowPage("vehicles");
  }
}

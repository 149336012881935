import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ApiService {
    
    httpOptions = {
        headers: new HttpHeaders({
          'accessToken': '',
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*'
        })
    };
  
    constructor( private httpClient: HttpClient) { }

    loginWithCpfPassword(params: any): Observable<any> {    
        
        let url = `${environment.officeApi}/authenticate`
        console.log(url);
        return this.httpClient.post(url, params , this.httpOptions).pipe(
          catchError(err => {
            console.log(err)
            throw err
          })
        )   
    }


    reqWithTokenRxjs(url: string, headers: any, body: any, method: string): Observable<any> {
        let response = null
        // let httpOptions = {
        //   headers: new HttpHeaders({
        //     'Authorization': localStorage.getItem('token') || environment.masterToken
        //   })
        // }
        let httpOptions = {
          headers: new HttpHeaders({
            'Authorization': localStorage.getItem('token')
          })
        }
    
        if (method == 'GET')
          response = this.httpClient.get(environment.officeApi + '/' + url, httpOptions)
        else if (method == 'POST')
          response = this.httpClient.post(environment.officeApi + '/' + url, body, httpOptions)
        else if (method == 'PUT')
          response = this.httpClient.put(environment.officeApi + '/' + url, body, httpOptions)
        else if (method == 'DELETE')
          response = this.httpClient.delete(environment.officeApi + '/' + url, httpOptions)
        else
          return null;
    
        return response
    }

}
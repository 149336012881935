import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  httpOptions = {
    headers: new HttpHeaders({
      'accessToken': localStorage.getItem('token'),
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    })
  };
  
  httpFileMessageOptions = {
    headers: this.httpOptions.headers,
    reportProgress: true,
    observ: 'events'
  };

  constructor( private httpClient: HttpClient ) { }

  // ************************** Whatsapp Baileys ******************************* //

  // connectApi(): Observable<any> {
      
  //   let url = `${environment.nodeApi}/whatsapp/connect`;
      
  //   let content = null;

  //   return this.httpClient.post(url, content, this.httpOptions)
  // }

  // getQrData(): Observable<any> {
    
  //   let url = `${environment.nodeApi}/whatsapp/getqrdata`;
    
  //   return this.httpClient.get(url, this.httpOptions).pipe(
  //       catchError(err => {
  //           console.log(err)  
  //           throw err
  //       })
  //       )
  // }
  

  // closeConnection(): Observable<any> {
    
  //   let url = `${environment.nodeApi}/whatsapp/closeconnection`;
    
  //   return this.httpClient.get(url, this.httpOptions).pipe(
  //       catchError(err => {
  //           console.log(err)  
  //           throw err
  //       })
  //       )
  // }

  // logout(): Observable<any> {
    
  //   let url = `${environment.nodeApi}/whatsapp/logout`;
    
  //   return this.httpClient.get(url, this.httpOptions).pipe(
  //       catchError(err => {
  //           console.log(err)  
  //           throw err
  //       })
  //       )
  // }

  // sendWhatsMessageByPhone(phone, message): Observable<any> {
      
  //   let url = `${environment.nodeApi}/whatsapp/sendmessagebyphone`;
      
  //   let body = {phone, message};

  //   return this.httpClient.post(url, body, this.httpOptions)
  // }

  // verifyIfCanUseWhatsapp(user): Observable<any> {
      
  //   let url = `${environment.nodeApi}/whatsapp/whatsappuser`;
      
  //   let body = {user};

  //   return this.httpClient.post(url, body, this.httpOptions)
  // }

  // isConnected(): Observable<any> {
    
  //   let url = `${environment.nodeApi}/whatsapp/isconnected`;
    
  //   return this.httpClient.get(url, this.httpOptions).pipe(
  //       catchError(err => {
  //           console.log(err)  
  //           throw err
  //       })
  //     )
  // }

  // verifyPhoneConnectionWhatsapp(): Observable<any> {

  //   let url = `${environment.nodeApi}/whatsapp/verifyphoneconnectionwhatsapp`;
    
  //   return this.httpClient.get(url, this.httpOptions).pipe(
  //       catchError(err => {
  //           console.log(err)  
  //           throw err
  //       })
  //     )
  // }

  // phoneConnectionWhatsappAfterLoginOrReloadPage(): Observable<any> {

  //   let url = `${environment.nodeApi}/whatsapp/phoneconnectionwhatsappafterloginorreloadpage`;
    
  //   return this.httpClient.get(url, this.httpOptions).pipe(
  //       catchError(err => {
  //           console.log(err)  
  //           throw err
  //       })
  //     )
  // }

  // ************************** Whatsapp Baileys ******************************* //







  // ******************* Whatsapp Venom ************************** //


  whatsappConnect(): Observable<any> {
      
    let url = `${environment.nodeApi}/whatsappvenom/connect`;
      
    //let content = null;

    return this.httpClient.get(url, this.httpOptions)
  }

  sendMessagewhatsapp(phone, message): Observable<any> {
      
    let url = `${environment.nodeApi}/whatsappvenom/send-message-by-phone`;
      
    let body = {phone, message};

    return this.httpClient.post(url, body, this.httpOptions)
  }
  
  sendContactWhatsapp(phoneContact, phoneSupportContact, nameSupportContact): Observable<any> {
      
    let url = `${environment.nodeApi}/whatsappvenom/send-contact-by-phone`;
      
    let body = {phoneContact, phoneSupportContact, nameSupportContact};

    return this.httpClient.post(url, body, this.httpOptions)
  }

  userIsLoggedWhatsapp(): Observable<any> {
      
    let url = `${environment.nodeApi}/whatsappvenom/user-is-logged`;
      
    //let content = null;

    return this.httpClient.get(url, this.httpOptions)
  }
  
  getQrData(): Observable<any> {
      
    let url = `${environment.nodeApi}/whatsappvenom/get-qrcode`;
      
    //let content = null;

    return this.httpClient.get(url, this.httpOptions)
  }
  
  getConfirmWhatsApp(role, managerId, token) : Observable<any> {
      
    let url = `${environment.nodeApi}/whatsappvenom/get-confirm-whatsapp`;
      
    let body = {role: role, managerId: managerId, token: token };

    return this.httpClient.post(url, body, this.httpOptions)
  }

  confirmWhatsappNumberAfterTest(role, managerId, token, phone) : Observable<any> {
      
    let url = `${environment.nodeApi}/whatsappvenom/confirm-whatsapp-after-test`;
      
    let body = {role: role, managerId: managerId, token: token, phone: phone };

    return this.httpClient.post(url, body, this.httpOptions)
  }

  // ******************* Whatsapp Venom ************************** //



}
 
import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-share-message',
  templateUrl: './share-message.component.html',
  styleUrls: ['./share-message.component.styl']
})
export class ShareMessageComponent {
  contacts: any
  contactsParams: Array <any>
  onPositive = new EventEmitter()

  constructor( @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contacts = data.contacts
  }

  shareMessage() {
    this.onPositive.emit(this.contactsParams)
  }

  contactsToShare($event) {
    this.contactsParams = $event
  }
}

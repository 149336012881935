import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavbarComponent } from './navbar/navbar.component';
import { MainContentComponent } from './main-content/main-content.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { NotificationsService } from './services/notifications.service';
import { CpfService } from './services/cpf.service';
import { ApiService } from './services/api.service';
import { MessagesComponent } from './messages/messages.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocketioService } from './services/socketio.service';
import { SelectContactComponent } from './messages/message-item/share-message/select-contact/select-contact.component';
import { ShareMessageComponent } from './messages/message-item/share-message/share-message.component';
import { MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { MessageItemComponent } from './messages/message-item/message-item.component';
import { SelectResourceComponent } from './messages/message-item/select-resource/select-resource.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component'
import { FooterComponent } from './footer/footer.component';
import { NgxMaskModule } from 'ngx-mask'
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(),
    PickerModule,
    QRCodeModule,
    NgxUiLoaderModule,
    
  ],
  entryComponents: [
    ShareMessageComponent,
    SelectResourceComponent,
    AlertDialogComponent,
  ],
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    MainContentComponent,
    NotAllowedComponent,
    ContactsComponent,
    MessagesComponent,
    VehiclesComponent,
    SelectContactComponent,
    ShareMessageComponent,
    MessageItemComponent,
    SelectResourceComponent,
    AlertDialogComponent,
     
  ],
  exports: [ MatDialogModule, MatProgressSpinnerModule ],
  providers: [ToastrService, NotificationsService, ApiService, SocketioService, CpfService],
  bootstrap: [AppComponent]
})
export class AppModule { }
